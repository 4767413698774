@use 'sass:color';
@use '../tools' as *;

.detailed-download {
  display: flex;
  justify-content: flex-end;
  justify-items: stretch;
  align-content: center;
  align-items: stretch;
  min-height: rem(60);
  max-height: rem(200);
  max-width: rem(500);
  margin: 1.2rem auto;
  border-radius: rem(5);
  background-color: $bg-ash;
  font-family: $open-sans;
  overflow: hidden;

  &.full-width {
    max-width: 100%;
  }

  .detail {
    position: relative;
    flex: 1;
    padding: 0.5rem;

    @extend %remove-child-bookend-margins;

    &.pad {
      padding-right: rem(22);
    }

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      border-style: solid;
    }

    &::before {
      transform: translateY(-50%);
      border-width: rem(100) 0 rem(100) rem(20);
      border-color: #ddd $white;
    }

    &::after {
      transform: translate(#{rem(-2)}, -50%);
      border-width: rem(100) 0 rem(100) rem(20);
      border-color: transparent $bg-ash;
    }

    & > * {
      position: relative;
      z-index: 1;
    }
  }

  .title {
    display: block;
    font-size: 1.1rem;
    font-weight: $semibold;
    text-align: center;
  }

  p, .description {
    display: block;
    margin: 0.3rem 0;
    line-height: 1.5;
  }

  .centered {
    text-align: center;
  }

  .link {
    font-weight: $semibold;
  }

  .icon {
    position: relative;
    width: rem(60);
    background-color: #ddd;
    font-size: 2.2rem;

    @include mobile {
      width: rem(50);
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dk-tag {
    display: block;
    min-width: auto;
    padding: 0 2px;
    border-radius: 0;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}

a {
  &.detailed-download, .detailed-download {
    color: $text-general;
    text-decoration: none;

    &, :not(i), .detail::before, .detail::after {
      transition: all 250ms ease;
    }

    .link {
      color: $link-standard;
    }
  }

  &:visited {
    &.detailed-download, .detailed-download {
      color: $text-general;

      .link {
        color: $link-visited;
      }
    }
  }

  &:hover {
    &.detailed-download, .detailed-download {
      $shade: color.adjust($bg-ash, $lightness: -5%);

      color: $text-general;
      text-decoration: none;
      background-color: $shade;

      .detail {
        &::before {
          @extend %accent-border;
          border-left-color: $white;
        }

        &::after {
          border-color: transparent $shade;
        }
      }

      .icon {
        color: $white;
        @extend %accent-background;
      }

      .link {
        color: $link-hover;
        text-decoration: underline;
      }

      .dk-tag {
        text-decoration: none;
      }
    }
  }
}
