@use 'sass:color';
@use 'sass:math';
@use '../tools' as *;

.usa-banner {
  font-size: rem(12);
  padding-bottom: 0;
  line-height: 1.6;
  background-color: $bg-gray;
  font-family: $montserrat;

  @include mobile {
    padding: 0 1rem;
  }
}

.usa-banner__header {
  position: relative;
  padding: rem(4) 0;

  &.usa-banner__header--expanded {
    padding-right: rem(46);

    .usa-banner__header-action {
      display: none;
    }

    @include mobile {
      button {
        &::after {
          content: $i-times;
          display: block;
          position: absolute;
          top: rem(6);
          right: rem(18);
          font-size: 1.5rem;
          color: $text-dark;
        }
      }
    }
  }

  @include mobile {
    padding: 0.5rem 0;
    min-height: 3rem;
  }
}

.usa-banner__inner {
  display: flex;

  @include mobile {
    button {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 2rem);
      height: 100%;
      margin-left: -1rem;

      .usa-banner__button-text {
        position: absolute;
        left: -999em;
        right: auto;
      }
    }
  }

  @include desktop(true) {
    .usa-banner__header-action {
      display: none;
    }

    button {
      margin-top: -1px;
      margin-left: 0.5rem;
    }

    button[aria-expanded=false]::after {
      content: $i-chevron-down;
    }

    button[aria-expanded=true]::after {
      content: $i-chevron-up;
    }
  }

  .usa-banner__header-action {
    color: $link-hover;
    text-decoration: underline;

    &::after {
      @extend %icon-base;
      content: $i-chevron-down;
      top: 0;
      margin-left: 5px;
      font-size: 0.8em;
      color: inherit;
    }
  }

  button {
    appearance: none;
    border: 0;
    padding: 0;
    background: none;
    font: inherit;
    color: $link-hover;
    cursor: pointer;

    span {
      text-decoration: underline;
    }

    &::after {
      @extend %icon-base;
      top: 0;
      margin-left: 2px;
      font-size: 0.8em;
      color: inherit;
    }

    @include tablet {
      margin-top: -2px;
    }
  }

  p {
    margin: 0;
  }
}

.usa-banner__inner-flag {
  flex: 0 1 auto;
  padding: 1px 7px 0 0;
}

.usa-banner__content {
  font-size: 1rem;
  padding: 1rem 0;

  & > div {
    display: flex;
    flex: 0 1 50%;
    align-items: flex-start;

    & > svg, & > img {
      flex: 0 0 auto;
      width: rem(40);
      height: rem(40);
      margin-right: 0.5rem;
    }

    p {
      margin: 0;
    }
  }

  @include desktop(true) {
    &:not([hidden]) {
      display: flex;
    }

    & > div {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  @include mobile {
    & > div + div {
      margin-top: 1rem;
    }
  }
}

.usa-banner__lock-image {
  height: 1.5ex;
  width: 1.21875ex;
}
