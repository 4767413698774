@use 'sass:color';
@use '../tools' as *;

// Home page styles
.pt-home {
  // Featured highlights slider
  .sc-slider {
    h2 {
      @extend %h2-standard;
      border-bottom: rem(2) $divider-stroke solid;
      color: $text-general;

      @include mobile {
        margin: 0 1rem 1rem;
      }
    }
  }

  // Home News
  .news {
    color: $white;
    font-family: $open-sans;
    padding: .5rem 0;

    @include mobile-tablet {
      padding: 1rem;
    }

    h2 {
      @extend %h2-standard;
      color: $white;
      border-bottom: rgba($white, 0.3) 1px solid;
    }

    .news-container {
      background-color: rgba($black, 0.25);
      padding: 1rem 1rem 0;
      margin-bottom: 2rem;
      @include row;

      .news-column {
        @include span-columns(6);
        @include omega;

        @include mobile {
          @include span-columns(12);
        }

        .news-item {
          margin-bottom: 1.5rem;

          @include desktop {
            padding-right: 1rem;
          }

          .news-date {
            font-weight: $semibold;
            font-size: rem(12);
          }
        }
      }

      .eld {
        &, &:visited, &:hover, &:active {
          color: $white;
          text-decoration: none;
        }
      }

      a {
        color: $white;
        text-decoration: underline;

        &:visited {
          color: color.mix(#000, $white, 10%);
        }
        &:hover, &:active {
          text-decoration: none;
        }
      }

      .more {
        margin-bottom: 1rem;
        padding-right: 1.5rem;

        @include span-columns(12);

        .cta::after, .dk-cta::after {
          border-color: $white;
          color: $white;
          @extend %icon-hover-fix;
        }
      }
    }
  }
}

// Full-width home hero banner
.home-hero {
  height: rem(320);
  overflow: hidden;

  @include desktop {
    background-color: hsla(202.78481012658227, 48%, 31%, 1);
    background-image: radial-gradient(circle at 79% 42%, hsla(201.17647058823525, 40%, 42%, 0.55) 13.268766196497209%, transparent 37.09847388776806%), radial-gradient(circle at 44% 55%, hsla(201.3559322033898, 37%, 33%, 1) 42.368825153377564%, transparent 72.07193751342953%), radial-gradient(circle at 97% 94%, hsla(185.2941176470588, 15%, 42%, 1) 41.398966539096655%, transparent 78.56549214277013%), radial-gradient(circle at 0% 0%, hsla(103.63636363636363, 5%, 42%, 1) 0%, transparent 43%), radial-gradient(circle at 2% 93%, hsla(51.42857142857147, 14%, 47%, 0.89) 54%, transparent 116%), radial-gradient(circle at 31% 5%, hsla(186.77419354838713, 28%, 57%, 1) 11.987295342341062%, transparent 63.773492222712875%);
  }

  @include mobile {
    background-color: #2d607b;
  }
}

// Full-width home health topics section
.home-health-topics {
  padding-top: 0;

  .topics-container {
    position: relative;
    background-color: $white;
    padding: 1rem;

    h2 {
      margin: 0.5rem 0 1.5rem;
      font-family: $merriweather;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.5rem;

      a:hover, a:visited {
        color: $link-standard;
      }

      a:not(:hover) {
        text-decoration: none;
      }
    }

    #hdng_home_htopics_es, #ul_home_htopics_es {
      display: none;
    }

    .topics-lang-toggle {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      padding: 0.333rem;
      border-radius: 2rem;
      font-size: 1rem;
      background-color: $link-standard;
      box-shadow: inset 0 rem(1) rem(10) rgba($black, 0.25);

      input {
        position: absolute;
        left: -5000px;
        z-index: -500;

        &:checked + label {
          color: $link-standard;
          cursor: default;
        }

        &:focus-visible + label {
          outline: 2px solid $text-red;
        }
      }

      label {
        position: relative;
        display: inline-block;
        width: rem(84);
        border-radius: 2rem;
        line-height: 1.75;
        text-align: center;
        font-family: $open-sans;
        font-weight: $semibold;
        color: $white;
        z-index: 2;
        cursor: pointer;
      }

      .lang-slider {
        display: block;
        position: absolute;
        top: 0.333rem;
        width: rem(84);
        height: 1.75em;
        border-radius: 2rem;
        color: $link-standard;
        background-color: $white;
        box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.125);
        z-index: 1;
        left: rem(6);
        transition: left 0.15s ease-out;
      }

      @include mobile {
        top: 1.625rem;

        label {
          width: rem(70);
          font-size: 0.875rem;
        }

        .lang-slider {
          width: rem(70);
          height: 1.5rem
        }
      }
    }

    &:has(#ipt_home_htopics_es:checked) {
      .lang-slider {
        left: rem(84) + 0.5rem;

        @include mobile {
          left: rem(70) + 0.5rem;
        }
      }

      #hdng_home_htopics_en, #ul_home_htopics_en {
        display: none;
      }
      #ul_home_htopics_es {
        display: grid;
      }
      #hdng_home_htopics_es {
        display: block;
      }
    }
  }

  @include desktop {
    margin-bottom: 1rem;
  }

  @include mobile {
    padding-bottom: 0;

    h2 {
      margin: 0 0 1rem;
    }
  }
}

// Full-width home director section
.home-director {
  padding: 2rem 0;
  font-family: $open-sans;

  &.bg-dark-blue {
    color: $white;
  }

  .dir-grid {
    display: grid;
    grid-template: "header photo" auto "links links" auto / auto #{rem(100)};
    gap: 1.5rem 0.5rem;
    align-items: center;
    margin: 0 1rem;
  }

  @include breakpoint($min: 800px) {
    .dir-grid {
      grid-template: "photo header links" auto / #{rem(120)} 1fr 2fr;
      gap: 0 1.5rem;
    }

    .header {
      justify-self: center;
    }
  }

  .photo {
    grid-area: photo;
    filter: drop-shadow(rem(8) rem(5) rem(1) rgba($black, 0.45));
  }

  .header {
    grid-area: header;
  }

  .links {
    grid-area: links;
  }

  h2 {
    margin: 0 0 0.5rem;
    font: normal 1.5rem $merriweather;
  }

  svg {
    display: block;
    width: 100%;
  }

  .ladder-links {
    margin: 0;

    a::after {
      color: inherit;
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.ladder-links {
  display: grid;
  gap: 1px 1rem;
  padding-top: 1px;
  font-family: $open-sans;

  @include desktop {
    @for $i from 2 through 3 {
      &.cols-#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);

        & > :nth-child(-n+#{$i})::before {
          content: '';
        }
      }
    }
  }

  & > * {
    display: grid;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;

    &::before, &::after {
      position: absolute;
      width: 100%;
      border-top: 1px solid $divider-stroke;
    }

    &::before {
      top: -1px;
    }

    &:first-child::before {
      content: '';
    }

    &::after {
      content: '';
      top: 100%;
    }
  }

  li {
    list-style: none;
  }

  a {
    display: block;
    position: relative;
    padding: 0.75rem 0.25rem;
    padding-right: 1.25rem;
    background-color: transparent;
    transition: 50ms background-color;

    &, &:visited, &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      transition: 100ms background-color;
    }

    &::after {
      @extend %angle-right-after;
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      color: $orange;
    }
  }
}

// Legacy home page hero
%home-hero-unit {
  $overview-cols: 8;
  $director-header-cols: 9.5;
  $nested-grid-cols: 12;

  background-image: url(../images/home-bg-hero.jpg);

  @include mobile {
    background-color: #2d607b;
  }

  .grid {
    overflow: hidden;
  }

  .description {
    @include row;
  }

  .overview {
    background: rgba($white, .15);
    min-height: 21rem;
    margin-top: rem(28);

    @include no-margin-collapse;
    @include span-columns($overview-cols of $nested-grid-cols);

    @include mobile {
      min-height: 19rem;
      margin-bottom: 0;

      @include span-columns(11.5);
      @include shift(.25);
    }

    hr {
      width: 70%;
      margin-top: rem(14);
      margin-bottom: rem(14);
      opacity: 0.2;
      border: 1px solid $white;
    }

    p {
      font-family: $merriweather;
      color: $white;
      text-align: center;
      font-size: 1.3rem;
      line-height: 2.8rem;
      text-shadow: rem(1) rem(1) $dark-gray;
      padding: 0 rem(70);
      margin-top: rem(8);

      &:first-child {
        margin-top: rem(40);
      }

      @include mobile-tablet {
        padding: 0 rem(25);
      }

      @include mobile {
        line-height: 2.5rem;
        padding: 0 rem(15);
      }

      .button-ghost {
        margin: 0.8rem 0rem;
        padding: 1rem 3rem;
      }
    }
  }

  .director {
    @extend %clearfix;
    display: block;
    padding: 0.7rem 0.7rem 1.2rem;
    position: relative;
    background-color: $white;
    padding-bottom: 50rem;
    margin-bottom: -50rem;

    @include span-columns($overview-cols of $nested-grid-cols);
    @include omega;

    @include mobile {
      margin-top: rem(24);
      padding-bottom: inherit;
      margin-bottom: inherit;

      @include span-columns( 11.5 of $nested-grid-cols);
      @include shift(.25);
    }

    &::after {
      height: rem(10);
    }
  }

  .health-topics {
    position: relative;
    background: $white;
    border-top: rem(8) $green solid;
    margin: 2rem 0 -50rem;
    padding: 0 1rem 50rem;

    @include span-columns(12 - $overview-cols of 12);
    @include omega;

    & {
      float: right;
    }

    @include mobile {
      margin-top: 0;
      padding-bottom: inherit;
      margin-bottom: inherit;

      @include span-columns(11.5);
      @include shift(.25);
    }

    h2 {
      @extend %h2-standard;
      border-bottom: .2rem $divider-stroke solid;
      margin-bottom: 0;

      a {
        color: $link-standard;
        text-decoration: none;
      }
    }

    ul {
      margin: 0 0 1.5rem;
      padding: 0;
      list-style-type: none;
      font: 1rem $open-sans;

      li {
        border-bottom: 1px $divider-stroke solid;
        margin: 0;
        padding: 0 0.5rem;
        text-align: left;
      }

      a {
        display: block;
        position: relative;
        margin: 0 -0.5rem;
        padding: 0.6rem 0.5rem;
        text-decoration: none;
        color: $text-dark;
        font-family: $open-sans;

        &::after {
          @extend %angle-right-after;
          position: absolute;
          right: 0.5rem;
          top: calc(50% - 7px);
          color: $orange;
        }

        &:hover, &:active {
          background-color: color.mix(#000, $white, 10%);
        }
      }
    }

    ul + p {
      margin-top: -0.5rem;
    }

    p {
      text-align: center;

      a.button {
        margin: 0;

        @include mobile {
          padding: 0.5rem 1rem;

          &.angle-right {
            padding-right: 1.8rem;

            &::after {
              right: 0.8rem;
            }
          }
        }
      }
    }
  }

  @include mobile-tablet {
    height: auto;
    padding-bottom: 2rem;
  }
}

.pt-home .hero-unit {
  @extend %home-hero-unit;
}
