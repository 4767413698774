@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use '../tools' as *;
@use 'main';

// Ensure that page theming is enabled
@include enable-themable-pages;

.contact-buttons {
  a {
    display: inline-block;
    width: rem(40);
    height: rem(40);
    font-size: 0;
    vertical-align: bottom;

    $contact-button-types: (
      mail: ($i-envelope-square, $link-standard),
      linkedin: ($i-linkedin-square, $linkedin-blue),
      researchgate: ($i-researchgate-square, $researchgate-teal),
      facebook: ($i-facebook-square, $facebook-blue),
      twitter: ($i-twitter-square, $twitter-blue),
      x-social: ($i-x-social-square, $x-social-black)
    );

    &::before {
      @extend %icon-base;
      font-size: rem(40);
    }

    & + .eld {
      display: none;
    }

    @each $cls, $arr in $contact-button-types {
      &.#{$cls} {
        &::before {
          color: list.nth($arr, 2);
          content: list.nth($arr, 1);
          @extend %icon-hover-fix;
        }

        &:hover::before {
          @if $cls == 'x-social' {
            color: color.adjust(list.nth($arr,2), $lightness: 12%);
          }
          @else {
            color: color.adjust(list.nth($arr,2), $lightness: -12%);
          }
        }
      }
    }
  }
}

.branch-header {
  margin: -1rem rem(-20) 0;
  padding: 0;
  background-size: cover;
  background-position: 50%;
  color: $white;
  text-shadow: 0.5px 0.5px 0.5px $black;

  &.im-lab-branch::before {
    $shade: rgba($black, 30%);

    content: '';
    display: block;
    height: rem(16);
    background-image: linear-gradient(0deg, #{$shade}, #{$shade});
    @extend %themable-bg;
  }

  .header-background {
    display: flex;
    flex-direction: column;
    border-color: inherit;
  }

  & * > i {
    margin-right: rem(5);
  }

  &.sub {
    margin: -1rem rem(-34) 0;

    .title {
      font-size: 1.5rem;
    }

    .chief, .chiefs {
      padding: 0.5em rem(28) rem(20);
    }

    .chiefs {
      gap: 0.5rem 2rem;

      .chief {
        padding: 0;
      }
    }
  }

  .title {
    margin: 0;
    padding: rem(20) rem(25) 0;
    font-family: $open-sans;
    font-size: 2rem;
    font-weight: $semibold;

    a, a:visited {
      color: $white;
    }
  }

  .no-chief {
    padding-top: rem(20);

    * {
      display: none;
    }
  }

  .chiefs {
    $hgap: 2.5rem;

    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem $hgap;
    padding: rem(20) rem(28);

    .chief {
      padding: 0;

      @include desktop(true) {
        flex: 0 1 auto;
        min-width: rem(250);
        margin-bottom: auto;

        @for $i from 2 through 2 { // in case this expands in the future
          &:first-child:nth-last-child(#{$i}) {
            &, & ~ * {
              $pct: math.percentage(math.div(1, $i));
              $adj: math.div($hgap, 2) * ($i - 1);
              max-width: calc(#{$pct} - #{$adj});
            }
          }
        }
      }
    }
  }

  &.landing .chief {
    display: grid;
    grid-template: "photo name" auto "photo title" auto "photo email" auto / auto 1fr;

    .chief-img {
      grid-area: photo;
      margin: 0.25rem 1rem 0 0;
      border-radius: 100%;
      max-width: rem(64);
    }

    .name {
      grid-area: name;
      font-size: 1.2rem;
    }

    .chief-title {
      grid-area: title;
    }

    .chief-email {
      grid-area: email;

      a {
        // Allow long email addresses to split when wrapping
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }

  .chief {
    line-height: 1.6;
    padding: rem(20) rem(28); // if not inside .chiefs

    p, a, div {
      margin: 0;
    }

    & *,
    & * > a:visited {
      color: $white;
    }

    & * > a, & > a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .branch-description {
    padding: rem(5) rem(30);
    background-color: rgba($white, 0.25);

    .branch-info {
      padding: 1rem 0;
      @extend %remove-child-bookend-margins;

      &, * {
        color: $white;
        line-height: 1.7;
        letter-spacing: 0.5px;
        @extend %size-h4;
      }
    }

    @include desktop {
      display: flex;

      .branch-info {
        flex: 3;
      }

      a.button-ghost {
        flex: 1;
        margin-left: rem(15);
        align-self: center;
        text-align: center;
      }
    }
  }

  @include mobile {
    margin: -1rem rem(-10) 0;

    &.sub {
      margin: -1rem rem(-24) 0;

      .chief, .chiefs {
        padding-bottom: rem(20);
      }
    }

    .branch-description {
      padding-bottom: 1rem;
      text-align: center;

      .branch-info {
        margin-right: 0;
        text-align: left;
      }
    }

    .title, .chief, .chiefs.chiefs, .branch-description {
      padding-left: rem(15);
      padding-right: rem(15);
    }
  }

  .dk-tabnav {
    margin: 0;
    background-color: rgba($white, 0.75);
    text-shadow: none;
  }
}

.section.sd-start-over {
  margin: 1rem 0;

  a {
    @extend %themable-border;
    @extend %themable-bg-link-hover;
  }
}

// Branch Landing Page
.branch-sections-list {
  ul:not(.single-column) {
    columns: rem(300) 2;
    break-inside: avoid-column;

    li {
      break-inside: avoid-column;
      page-break-inside: avoid;
    }
  }
}

.branch-sections > .box {
  padding: 1.25rem;
}

// Section Header
.branch-header.section {
  .title-container {
    .title~p {
      margin: 0;
      padding: 0 rem(25) 0;

      &, & * {
        color: $white;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  & > p {
    margin: 0;
    padding: 0 rem(25);
  }

  .branch-description {
    display: block;
    margin: 0 rem(20);
    padding: rem(5) rem(20);

    img {
      display: none;
    }

    .branch-button {
      display: none;
    }
  }

  // Add to branch-header for Options 2-4 of the Section Landing
  &.right-description {
    margin-bottom: rem(20);

    .header-background {
      flex-direction: row;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-right: 1rem;
      justify-content: center;
    }

    .chiefs {
      padding-right: 0;

      .chief {
        max-width: none;
      }
    }

    .branch-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin: rem(20);
      padding: rem(20);
      border-style: solid;
      border-width: rem(5) 1px 1px;
      border-color: inherit;
      background-color: rgba($white, 0.75);

      .info-container {
        display: flex;
        width: 100%;

        img {
          display: block;
          align-self: flex-start;
          max-width: 30%;
          margin-top: 0.3rem;
          margin-right: 1rem;
          object-fit: contain;
        }

        .branch-info {
          flex: auto;
          margin-right: 0;
          padding: 0;
          font-weight: normal;
          text-shadow: none;
          @extend %remove-child-bookend-margins;

          &, * {
            font-size: 1rem;
            font-weight: bold;
            color: $text-general;
            line-height: 1.6;
            letter-spacing: normal;
          }
        }
      }

      .branch-button {
        display: block;
        margin: 1.5rem 0 0;

        a {
          display: inline-block;
          padding: rem(10) rem(40);
          border-radius: rem(3);
          font-family: $open-sans;
          color: $white;

          &:hover {
            text-decoration: none;
          }

          &::after {
            @extend %icon-base;
            content: $i-angle-right;
            margin-left: rem(10);
          }
        }
      }
    }
  }

  @include mobile {
    .title-container {
      .title + p {
        padding: 0 1rem;
      }
    }

    .branch-description {
      margin: 0;
    }

    &.right-description {
      margin-bottom: rem(10);

      .header-background {
        flex-direction: column;
      }

      .title-container {
        width: 100%;
      }

      .branch-description {
        width: 100%;
        margin: 0;
        padding: rem(20) rem(15);
        border: 0;

        .info-container {
          display: block;

          img {
            float: left;
            margin-right: 0.75rem;
          }
        }
      }
    }
  }
}

// Section Landing Page
.branch-button-lower {
  display: flex;
  justify-content: center;
  margin-bottom: rem(20);
  padding: rem(20);
  background-color: rgba(#e6e6e6, 0.5);

  a {
    display: inline-block;
    width: rem(250);
    padding: 1rem;
    border-radius: rem(5);
    font-family: $open-sans;
    text-align: center;
    text-shadow: 0.5px 0.5px 0.5px $black;
    color: $white;

    &::after {
      @extend %icon-base;
      content: $i-angle-right;
      margin-left: rem(10);
      font-size: 1.2em;
      vertical-align: middle;
      transform: translateY(#{rem(-2)});
    }

    &, &:visited {
      color: $white;
    }

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }

  @include mobile {
    margin: 0 rem(-10) rem(10);
  }
}

.section-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  margin: rem(20) 0;

  &::after {
    content: "";
    display: block;
    flex: 0 0 auto;
    width: calc(33.33% - 0.7143rem);
  }

  & + .section-tiles {
    margin-top: rem(-20);
  }

  .page-tile {
    display: flex;
    flex: 0 0 auto;
    width: calc(33.33% - 0.7143rem);
    //min-height: rem(170);
    margin-bottom: 1rem;
    padding: rem(30) rem(10);
    border: 1px solid;
    border-color: inherit;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    justify-content: flex-start;

    & > * {
      display: block;
      width: 100%;
    }

    & > :first-child:last-child {
      margin: auto 0;
    }

    *[class^="i-"],
    *[class*=" i-"] {
      height: 5rem;
      margin-bottom: 1rem;
      object-fit: contain;
      font-size: 5rem;
      line-height: 1;
    }

    img {
      width: auto;
      max-height: 5rem;
      margin-bottom: 1rem;
    }

    span {
      @extend %size-h4;
      font-family: $open-sans;
      font-weight: $semibold;
      line-height: 1.3;
    }
  }

  .large-tile {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    padding: rem(10);
    border-style: solid;
    border-width: rem(8) 1px 1px;
    border-color: inherit;
    @extend %remove-child-bookend-margins;

    &.half-width {
      width: calc(50% - 0.5rem);
    }

    // Don't let a half-width box end up on the last row by itself
    &:not(.half-width) + .half-width:last-child,
    &.half-width:first-child:last-child {
      width: 100%;
    }

    & > * {
      margin: 0.5rem 0;
      @extend %remove-child-bookend-margins;
    }

    .image {
      text-align: center;
      line-height: 0;

      img {
        display: inline;
        max-width: 100%;
        max-height: rem(300);
      }
    }

    .lab-news-image {
      max-width: rem(480);
    }

    h2 {
      color: $text-dark;
    }

    div, p {
      line-height: 1.5;
    }

    p, ul, ol {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .cta {
      margin-top: auto;
    }
  }

  @include mobile {
    &:last-child {
      margin-bottom: 0;
    }

    .page-tile, &::after {
      width: 100%;
    }

    .page-tile {
      margin-bottom: rem(10);
    }

    .large-tile {
      margin-bottom: rem(10);

      &.half-width {
        width: 100%;
      }
    }
  }
}

// Clinical Trials
.ct-study {
  margin: 1rem 0;
  padding-left: 1rem;
  border-left: solid rem(5) $block-accent-gray;

  & + .ct-study {
    margin-top: 2rem;
  }

  & > * {
    margin: 0.75rem 0;

    p {
      margin: 0.5rem 0;
    }
  }

  &, p {
    line-height: 1.5;
  }

  .ct-status {
    margin-bottom: 1rem;
  }

  .ct-contact {
    display: inline-block;
    margin-left: 0.5rem;

    // Hide legacy markup if present
    .i-envelope-o, .i-mobile { display: none; }
  }

  .contact-buttons a {
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
    transform: translateY(#{rem(-3)});

    &::before {
      font-size: 2.5rem;
    }
  }

  @include mobile {
    .ct-contact {
      display: block;
      margin: 0.5rem 0;
    }
  }
}

.ct-status {
  $clinical-trial-status-colors: (
    not-recruiting: $orange,
    recruiting: $green,
    enrolling: #8c3668,
    expanded-access: $bg-lavender
  );

  display: block;
  font-size: 0;
  @extend %clearfix;

  & > * {
    display: block;
    float: left;
    padding: rem(2) rem(15);
    font-family: $open-sans;
    font-weight: $semibold;
    font-size: 1rem;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-color: $text-general;
    line-height: 1.5;

    @include mobile {
      padding: rem(2) rem(10);
      font-size: 0.85rem;
    }
  }

  & > :first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: $text-general;
    color: $white;
  }

  & > :last-child {
    border-right-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  @each $class, $color in $clinical-trial-status-colors {
    &.#{$class} {
      & > * {
        border-color: $color;
        color: $color;
      }

      & > :first-child {
        background-color: $color;
        color: $white;
      }
    }
  }
}

// Section Members
.staff-container {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 3rem;

  &.v2 .staff-member .staff-photo {
    height: rem(120);

    img {
      max-width: rem(120);
      max-height: rem(120);
    }
  }

  .staff-member {
    display: grid;
    grid-template: min-content 1fr / #{rem(120)} 1fr;
    gap: 0.5rem 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $divider-stroke;
    color: $text-general;
  }

  .staff-detail, .staff-description {
    grid-column: 1 / span 2;
  }

  .staff-photo {
    position: relative;
    width: rem(120);
    height: rem(180);
    margin-top: 0.2rem;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: none;
      max-height: rem(180);
    }

    &:empty {
      background: url(../images/staff-generic.png) 50% 50% no-repeat;
    }

    & ~ .staff-detail {
      grid-column: 2;
    }
  }

  .staff-detail {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 0.3rem;

    @extend %remove-child-bookend-margins;
  }

  .staff-name {
    font-weight: bold;
  }

  .staff-description, p {
    line-height: 1.8;
  }

  .staff-description {
    @extend %remove-child-bookend-margins;

    p, ul, ol {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .social-contact-links {
    margin-top: 0.2rem;
  }

  @include desktop {
    &.tiles, &.partial-description {
      .staff-member {
        padding: 0;
        border: 0;
      }
    }

    &.tiles {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3rem 2rem;

      .staff-member {
        grid-template-columns: 1fr;

        & > * {
          grid-column: 1;
        }
      }

      .staff-photo {
        margin: 0 auto;

        img {
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .staff-name, .staff-title {
        text-align: center;
      }

      .social-contact-links {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.full-description {
      .staff-photo {
        grid-row-end: span 2;

        & ~ .staff-description {
          grid-column: 2;
        }
      }
    }

    &.partial-description {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }
}

// Section News
.section-news-date-filter {
  @extend %news-horizontal-date-links;
}

.lab-news-image .corner {
  @extend %video-corner;
  transform: none;
}

.section-news-item {
  margin-bottom: 1.5rem;
  border-bottom: 2px solid $divider-stroke;
  @extend %clearfix;

  img {
    display: block;
    margin: 0 auto;
  }

  .lab-news-image {
    &:not(.ratio) img {
      max-height: rem(300);
    }
  }

  h2 {
    @extend %size-h3;
  }

  time {
    font-family: $open-sans;
    font-weight: $semibold;
  }

  .read-more {
    margin: 1rem 0 1.5rem;

    a {
      @extend %button;
      margin: 0;
      padding: 0.5rem 1rem;

      &::after {
        @extend %angle-right-after;
        transform: translateY(-1px);
      }
    }
  }

  @include desktop {
    .lab-news-image {
      float: right;
      width: rem(280);
      margin: 0.5rem 0 1.5rem 1rem;

      &:not(.ratio) img {
        max-width: rem(280);
      }
    }
  }
}
