@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use '../tools' as *;

// Add accent color classes;
@include enable-accent-color-classes;

// Ensure that page theming is enabled
@include enable-themable-pages;

main {
  display: block;
  font-family: $font-serif;
  color: $text-general;

  @include enable-body-elements;
  @include enable-images-figures;
}

@include enable-icons;
@include enable-utility-classes;

// Grid sizing
main.left-nav {
  nav {
    @include span-columns(3);
  }

  .main-content {
    @include span-columns(9);
  }
}

.thirds {
  @include span-columns(4);
}

.half {
  @include span-columns(6);
}

.two-thirds {
  @include span-columns(8);
}

// Horizontal lists
ul, ol {
  &.horizontal-links {
    list-style: none;
    padding: 0;

    li {
      display: inline;

      &::after {
        content: ' |';
        display: inline;
      }

      &:last-of-type::after {
        content: '';
      }
    }
  }
}

// main content area (kitchen-sink)
.dk-two-column .dk-breadcrumb-row {
  min-height: rem(30);
}

.dk-breadcrumb-row { // will also include language dropdown
  margin: rem(14) 0;
  position: relative;
  z-index: 27; // Above subscribe/follow (25) and below main nav (30)

  @include row;

  @include desktop {
    display: flex;
  }

  @include mobile {
    display: block;
  }

  .dk-breadcrumbs {
    flex: 6;
    margin-top: 0.6rem;

    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        font-family: $open-sans;
        font-size: 1rem;
        margin: 0;
        color: #343638;

        a {
          text-decoration: none;
          font-weight: bold;

          &, &:visited {
            color: $link-hover;
          }

          &:hover, &:active {
            text-decoration: underline;
          }
        }
        &:not(:last-child)::after {
          content: ' \\ '
        }
      }
    }

    @include mobile {
      display: none;
    }
  }
}

.dk-main-container {
  position: relative;
  background: $white;
  box-shadow: 0 rem(1.5) rem(16) rgba($black, 0.1);
  margin: 1rem 0;
  font-family: $merriweather;
  padding: 0 rem(20);

  @include row;

  @include mobile {
    padding: 0 rem(10) rem(35);
  }

  @include print {
    box-shadow: none;
    padding: 0;
  }

  .dk-leftnav {
    margin: {
      top: 1.5rem;
      bottom: 1.5rem;
    }

    @include span-columns(3);
  }

  .landing-hero {
    @include row;

    @include desktop {
      & > :first-child {
        @include span-columns(8);

        & > :first-child {
          margin-top: 0;
        }
      }

      & > :last-child {
        @include span-columns(4);

        &.right {
          margin: {
            top: 0;
            left: 0;
          }
        }
      }

      & > figure:last-child {
        display: table;
      }

      // Full width when there is only one child
      & > :first-child:last-child {
        @include span-columns(12);
      }
    }
  }

  // Content area takes up 9 columns instead of 12 when left nav is present
  .dk-leftnav + .dk-content {
    @include desktop {
      max-width: rem(688);

      @include span-columns(9);
    }

    @include mobile {
      max-width: rem(628);
    }
  }

  .dk-content {
    padding: rem(14);
    min-height: rem(400);

    @include span-columns(12);

    p.dk-intro {
      font-weight: bold;
    }

    // reviewed date
    .dk-review-date {
      @extend %block-vmargin;
      @extend %dk-paragraph-text;
      font-weight: bold;
    }

    & > article {
      border-bottom: solid 1px $divider-stroke;
    }
  }
}

:is(.dk-cta, .cta) {
  $cta-color: var(--cta-color, #{$text-general});
  display: block;
  position: relative;
  text-align: right;
  margin-top: 1rem;
  padding-right: 1.3em;
  font-family: $open-sans;
  color: $cta-color;

  &:where(a) {
    text-decoration: none;

    &:is(:visited, :hover, :active) {
      color: $cta-color;
    }

    &:is(:hover, :active) {
      text-decoration: underline;

      &::after {
        @extend %icon-hover-fix;
      }
    }
  }

  &::after {
    @include icon-base;
    content: $i-arrow-right;
    position: absolute;
    top: calc(50% - 0.5em);
    right: 0;
    color: inherit;
    vertical-align: middle;
    text-decoration: none;
  }
}

.action-link {
  display: block;
  position: relative;
  text-align: left;
  margin: rem(14) 0;
  color: $text-dark;
  line-height: 1.6;

  &, &.thin-box {
    padding: 1rem 0.5rem 1rem rem(28);
  }

  &::before {
    @extend %double-chevron-right;
    position: absolute;
    top: 50%;
    left: 0;
    width: rem(28);
    height: rem(22);
    padding: 0;
    transform: translateY(-50%);
    font-size: 1.6rem;
    text-align: center;
  }

  a:not(.no-arrow)::after, &.end-arrow::after {
    @extend %chevron-circle-right;
    @extend %icon-hover-fix;
    color: $text-general;
    vertical-align: middle;
    text-decoration: none;
    margin-top: rem(-2);
  }

  &.end-arrow a::after {
    content: normal;
  }

  &.no-padding.no-padding.no-padding {
    padding-left: rem(28);
  }

  .eld {
    text-indent: 0;
  }
}

.box-filled {
  margin: 1rem 0;
  padding: rem(10);
  color: $white;
  text-align: center;
  text-shadow: 1px 1px rgba($black, 0.5);

  @extend %accent-background;

  :is(h1, h2, h3) {
    @extend %size-h2;
    font-family: $open-sans;
  }

  * {
    color: $white;
  }

  .button-pair {
    display: flex;
    margin: 2rem;

    & > * {
      display: flex;
      align-self: stretch;
      align-items: center;
      flex: 1;
      width: 100%;
      padding: 0.5rem 0.5rem;
      margin: 0 0.5rem;
      text-align: center;
      line-height: 1.3;

      & > span {
        width: 100%;
      }

      &.button-ghost::after {
        content: normal;
      }
    }

    @include mobile {
      display: block;

      & > * {
        max-width: rem(300);
        margin: 0 auto;
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.dk-more-info {
  text-align: right;

  a {
    text-decoration: none;
    color: $text-general;
  }

  a::after {
    @extend %chevron-circle-right;
  }
}

// Breakpoint-conditional line breaks
br {
  &.mobile, &.m, &.tablet, &.t, &.desktop, &.d, &.full, &.f {
    display: none;
  }
  &.mobile, &.m {
    @include mobile {
      display: inline;
    }
  }
  &.tablet, &.t, &.full, &.f {
    @include tablet {
      display: inline;
    }
  }
  &.desktop, &.d, &.full, &.f {
    @include desktop-only {
      display: inline;
    }
  }
}

[id],
a[name]:not([href]) {
  @extend %anchor-margin;
}

.browserupgrade {
  margin: 0.2em 0;
  background: $button-outline;
  color: $black;
  padding: 0.2em 0;
}

a.button {
  @extend %button;

  &.gray {
    background-color: $button-gray;
    color: $button-text;
    border: 1px solid $button-outline;

    &:visited {
      color: $button-text;
    }

    &:hover {
      background: color.mix(#000, $button-gray, 5%);
      color: $button-text;
      text-decoration: none;
    }
  }
}

.button-row {
  @extend %button-row;
}

.button-ghost {
  @extend %button-ghost;
}

.button.angle-right::after {
  margin-top: rem(-2);
}

.button.angle-left::before {
  @include icon-base;
  content: $i-angle-left;
  margin-top: rem(-2);
  padding-right: 0.5em;
  font-size: 1.2em;
  vertical-align: middle;
}

.dk-language-dropdown {
  $outline-color: #cfcfcf;
  position: relative;
  display: block;

  @include dropdown-generic;
  @include span-columns(2 of 12);
  @include omega;

  @include desktop {
    flex: 1;
    align-self: flex-end;
    margin-left: rem(5);
  }

  @include mobile {
    float: right;
    padding-right: rem(10);
    width: 35%;
  }

  button[name=options] {
    width: 100%;
    height: 2.5rem;
    border: 1px solid $outline-color;
    border-radius: rem(3);
    font-weight: $semibold;
    font-size: rem(14);
    background-color: $white;

    span {
      display: block;
      position: relative;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        @extend %icon-base;
        content: $i-angle-down;
        display: block;
        position: absolute;
        right: -3px;
        top: 50%;
        width: rem(10) + 1rem;
        transform: translateY(-50%);
        padding: rem(5) 0 rem(5) rem(10);
        color: $link-standard;
        background-image: linear-gradient(to right, #{rgba($white, 0)}, $white 50%);
      }
    }
  }

  ul {
    left: rem(-7);
    right: rem(-7);
    top: calc(100% + #{rem(4)});
    border-color: $outline-color;

    @include mobile {
      left: rem(-7);
      right: rem(3);
    }

    li {
      margin: 0;
      padding: 0;
      border-color: $outline-color;

      & > * {
        display: block;
        padding: 0.6rem {
          right: 7rem;
        }
      }
    }

    &::before {
      right: rem(5);
      left: auto;
    }

    a {
      text-decoration: none;
      color: $button-text;
    }
  }
}

// Overlays
.site-wrap {
  position: relative;

  .page-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -100;
    height: 100%;
    width: 100%;
    transition: background-color 0.25s, z-index 0s 0.25s;

    .sn-close-nav {
      visibility: hidden;
    }
  }
}

@include mobile {
  .site-wrap.overlay .page-mask {
    @include page-mask;
  }

  .site-wrap.search-overlay .page-mask {
    @include page-mask(60, $black, 0.9);
  }

  .site-wrap.popup-overlay .page-mask {
    @include page-mask(50, $black, 0.5);
  }

  // Used to stop scrolling on body (deprecated)
  body.modal-open {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.site-wrap.subscribe-overlay .page-mask {
  @include page-mask(40, $white, 0.5);
}

// Feature box styling
.#{$box-border-prefix}thick {
  $thickness: rem(10);
  $position: -1 * $thickness;
  $border-color: $button-gray;

  position: relative;
  margin: $thickness;
  box-shadow: 0 0 rem(5) rem(2) color.mix(#000, $border-color, 5.5%);

  &::before {
    z-index: -1;
    display: block;
    content: '';
    position: absolute;
    background: $border-color;
    top: $position;
    bottom: $position;
    left: $position;
    right: $position;
  }
}

// Background color classes
@each $class, $color in $background-colors {
  .#{$class} {
    background-color: $color;
  }
}

%file-icon {
  $size: 1.25em;

  display: inline-block;
  width: $size;
  height: $size;
  margin-top: -0.5 * ($size - 1);
  vertical-align: middle;
}

:is(.file, [class^="file-"], [class*=" file-"]):where(:not(.file-size))::before {
  @extend %file-icon;
  content: url($file-icon-url-generic);
  font-size: 1.01em;
}

@each $id, $suffixes in $file-icon-map {
  $sel: join-string($suffixes, $prefix: '.file-');

  :is(#{$sel})::before {
    $url: $file-icon-url + '#' + $id;
    content: url($url);
  }
}

.eld {
  font-size: 0;
  text-indent: 5000rem;

  &::before {
    font-size: 1rem;
    text-indent: 0;
  }

  @at-root .exit-no-break + &::before {
    display: inline;
  }

  &, &:visited, &:hover, &:active {
    color: var(--icon-color, #{$text-green});
    text-decoration: none;
  }
}

// hero unit/top banner
.hero-unit {
  width: 100%;
  padding: 0;
  position: relative;
  background-size: 100% 23rem;
  background-repeat: no-repeat;
  background-position: center top;

  @include mobile {
    background-size: cover;
  }
}

section.centered-white {
  color: $white;
  font-family: $open-sans;
  text-align: center;
  padding: 2rem 0 3rem;

  h2 {
    font-weight: normal;
    font-family: $merriweather;
    font-size: 1.5rem;
    border-bottom: 0.1rem solid $bg-gray;
    display: inline-block;
    padding-bottom: 1.2rem;
    margin: 0 rem(10);
  }

  p {
    font-size: rem(17.43);
    margin: 1.2rem;
    color: $white;
  }

  @include print {
    color: $dark-gray;

    p {
      color: $dark-gray;
    }
  }
}

.dk-card-container {
  background: $bg-white;
  box-shadow: 0.1rem 0.1rem 0.3rem $light-gray;
  padding: 1rem 1.5rem;
  font-family: $open-sans;

  @include row;

  h2 {
    @extend %h2-standard;
    border-bottom: 0.1rem rgba($divider-stroke, 0.5) solid;
  }

  &.dk-box h2 {
    margin: 0;
    padding-top: 0;
  }
}

.dk-card-row {
  overflow: hidden;

  @include row;
}

.dk-box:not(.dk-card-container) > .dk-card-row {
  margin: 0 1rem 1rem;
}

.dk-box.dk-box .dk-box-content > .dk-card-row {
  &:first-child {
    margin-top: rem(-20);
  }

  &:last-child {
    margin-bottom: rem(-6);
  }
}

.dk-box.border-thick .dk-box-content > .dk-card-row {
  margin-left: rem(-6);
  margin-right: rem(-6);
}

.dk-card {
  background: $bg-gray;
  margin: {
    top: 1rem;
    bottom: -20rem;
  }
  padding-bottom: 20rem;
  font-family: $open-sans;

  @include span-columns(4);
  @include omega(4n);

  img {
    max-width: 100%;
    height: auto;
  }

  &.media-card {
    .dk-card-title {
      color: $text-general;
    }
  }

  .dk-card-title {
    display: block;
    text-decoration: none;
    color: $link-standard;
    font-weight: $semibold;
    font-size: rem(16); // This should be 18
    margin: 0.6rem 0;
    padding: 0 0.8rem 0;
  }

  p {
    margin: 0.6rem 0 1rem;
    padding: 0 0.8rem 0;
    line-height: 1.4;
    font-size: rem(14);
  }

  @include mobile {
    padding: rem(10) rem(5);
    margin-bottom: 0;

    &.dk-card img {
      float: left;
      width: 30%;
    }

    p, .dk-card-title {
      float: right;
      padding: 0;
      width: calc(70% - #{rem(7)});
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @include span-columns(12);
  }
}

a.dk-card {
  outline: none;

  &:visited {
    .dk-card-title {
      color: $link-visited;
    }
  }

  &:hover, &:active, &:focus {
    text-decoration: none;

    .dk-card-title {
      color: $link-hover;
      text-decoration: underline;
    }
  }

  p {
    color: $text-general;
  }
}

// Dictionary terms
.dictionary-term {
  &, &:visited {
    color: $link-standard;
    text-decoration: none;
    border-bottom: 1px dashed $link-standard;
  }

  &:hover, &:active {
    color: $link-hover;
    text-decoration: none;
  }
}

dl.term-definition {
  margin: 0 0 1rem;

  dfn {
    font-style: normal;
  }

  dt {
    @extend %size-h2;
    margin: 1rem 0;
    font: {
      family: $open-sans;
      weight: $semibold;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    @extend %p;
    font: {
      family: $merriweather;
      size: 1rem;
    }
    margin: 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.pronunciation {
      font-family: $open-sans;
    }
  }
}

.twitter-container {
  @include row;

  .i-twitter {
    color: $twitter-blue;
  }

  @include desktop {
    .wrap {
      @include span-columns(9);
      @include shift(1.5);
      @include omega;
    }
  }
}

// Roll-up of pages with images, title, and short description
%page-rollup-item {
  list-style: none;
  margin: 1rem 0;
  padding: 0;

  & > * > * {
    @extend %remove-bookend-margins;
  }

  .title {
    margin: 1rem 0;
    font-weight: bold;
    font-family: $open-sans;
  }
}

%page-rollup {
  margin: 1rem 0;

  & > * {
    @extend %page-rollup-item;

    &:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: solid 1px $divider-stroke;
    }
  }

  & + %page-rollup, & + h2, & + h3 {
    margin-top: 2.5rem;
  }
}

.page-rollup {
  @extend %page-rollup;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @include desktop {
    & > * {
      @include row;

      & > :first-child:nth-last-child(2) {
        @include span-columns(5 of 12);
      }

      & > :last-child:nth-child(2) {
        @include span-columns(7 of 12);
      }

      .title {
        margin-top: 0;
      }
    }

    &.meet-director > * {
      @include row;

      & > :first-child:nth-last-child(2) {
        padding-top: rem(5);

        @include span-columns(3.5 of 12);
      }

      & > :last-child:nth-child(2) {
        @include span-columns(8.5 of 12);
      }
    }
  }
}

.youtube-rollup {
  @extend %page-rollup;
}

.youtube-rollup > *, .youtube-summary {
  @extend %page-rollup-item;

  & > :first-child:nth-last-child(2) {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (math.div(22, 40) * 100%);
    }

    iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include desktop {
  .youtube-summary.columns, .youtube-rollup:not(.stack) > :not(.stack) {
    @include row;

    & > :first-child:nth-last-child(2), & > :last-child:nth-child(2) {
      @include span-columns(6 of 12);
    }

    .title {
      margin-top: 0;
    }
  }
}

.image-resources {
  list-style: none;
  margin-top: 1.5rem;

  @include row;

  & > * {
    display: block;
    margin: 0;
    list-style: none;
    margin-bottom: 1.5rem;

    & > :first-child {
      display: block;
      position: relative;
      border: solid 1px $divider-stroke;
      padding-bottom: calc(100% - 2px);
      overflow: hidden;

      & > :first-child {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & > :first-child {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      * {
        display: none;
      }
    }

    & > :last-child {
      display: block;
      line-height: 1.8rem;
      margin-top: rem(10);

      & > * {
        margin: rem(8) 0;
      }

      @extend %remove-child-bookend-margins;
    }

    @include desktop {
      @include span-columns(6 of 12);

      &:nth-child(2n+1) {
        clear: left;
      }

      &:nth-child(2n) {
        @include omega;
      }
    }
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.banner-cta {
  position: relative;
  margin: 1rem 0;
  color: $white;
  background: {
    color: $bg-blue-green;
    size: cover;
    position: left center;
    repeat: no-repeat;
  }
  font-family: $open-sans;

  @include row;

  a:not([class*="button"]) {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.email::before {
      color: $white;
    }
  }

  i.eld {
    color: $white;
  }

  img {
    max-width: 60%;
  }

  .text {
    float: right;
    max-width: 50%;
    padding: 1.3rem;
    background-color: $bg-blue-green;

    & > p:first-of-type, & > h3:first-of-type {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    line-height: 1.1;
  }

  p {
    color: $white;
    line-height: 1.4;
  }

  .button-ghost {
    display: block;
    margin: 0 auto;
    padding: 1em 0;
    text-align: center;
  }

  .cta {
    text-align: center;
    color: $white;
    padding: 0;

    &:visited, &:hover, &:active {
      color: inherit;
    }

    &::after {
      color: $white;
      position: static;
      margin-left: rem(5);
    }
  }

  .step-up-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
  }

  @include mobile {
    padding-top: 56%;
    background: {
      size: contain;
      position: left top;
    }

    .text {
      position: relative;
      max-width: 100%;
      float: none;
    }

    .step-up-logo {
      bottom: 100%;
      width: 25%;
    }
  }
}

.header-with-link {
  margin: 1rem 0;

  @include row;

  h2 {
    float: left;
    margin: 0 1rem 0 0;
  }

  a, a.cta {
    display: block;
    float: right;
    line-height: rem(33);
    margin: 0;
  }
}

a.social-link {
  font-family: $open-sans;
  font-weight: $semibold;
  font-size: 1.1em;
  color: $text-general;

  &::after {
    display: inline-block;
    width: rem(24);
    height: rem(24);
    margin-left: rem(5);
    border-radius: rem(5);
    font-family: $dk-icons;
    font-size: rem(16);
    line-height: 1.6;
    font-weight: normal;
    text-align: center;
    vertical-align: top;
    @include icon-hover-fix;
  }

  &.twitter {
    &::after {
      content: $i-twitter;
      color: $white;
      background-color: $twitter-blue;
    }

    &:hover::after {
      background-color: color.adjust($twitter-blue, $lightness: -10%);
    }
  }

  &.facebook {
    &::after {
      content: $i-facebook;
      color: $white;
      background-color: $facebook-blue;
    }

    &:hover::after {
      background-color: color.adjust($facebook-blue, $lightness: -10%);
    }
  }

  & + a.social-link, & + .eld + a.social-link {
    margin-left: 1em;
  }

  & + .eld {
    display: none;
  }
}

.scorm-launch {
  max-width: rem(330);
  margin: 2rem auto;

  @extend %remove-child-bookend-margins;

  .button.button {
    margin: 0;
    width: 100%;
    text-align: center;
    padding: {
      top: rem(10);
      bottom: rem(10);
    }

    @include mobile {
      background-color: #d2d0ca;
      color: $text-general;
      cursor: default;
    }
  }

  p {
    font-size: rem(12.5);
  }
}

.image-beside-link {
  display: table;
  width: calc(100% + #{rem(30)});
  margin: rem(-15);
  table-layout: fixed;
  border-spacing: rem(15);
  font-size: rem(18);
  font-weight: $semibold;

  @include mobile {
    font-size: rem(16);
  }

  & > * {
    display: table-cell;
    vertical-align: middle;
  }

  & > :first-child {
    width: 30%;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 0 0 0 0;
    }
  }

  & > :last-child {
    width: 70%;
  }
}

.dk-tag {
  @extend %dk-tag;
  margin-right: 0.25rem;
  font-weight: $semibold;
  line-height: 1.6;

  &.updated {
    background-color: $orange; //#a66818;
  }

  &.new {
    background-color: $green; //#607f3c;
  }
}

.news-type {
  @extend %dk-tag;
  min-width: rem(110);
  padding: rem(2) rem(5);
  font-size: 12px;

  &.news-release {
    background-color: #204c8a;
  }

  &.research-update {
    background-color: #ac640c;
  }
}

.news-dateline {
  @extend %news-dateline;
}

.fs-return-link {
  &.hide-no-ref {
    display: none;
  }

  a {
    font-family: $open-sans;

    &, &:visited, &:hover, &:active {
      color: $text-general;
    }

    &::before {
      @extend %icon-base;
      @extend %icon-hover-fix;
      content: $i-arrow-left;
      position: relative;
      top: 2px;
      margin-right: rem(5);
    }
  }
}

.news-with-image {
  @include row;

  & > .image.image.image img {
    margin: 0;

    @include mobile {
      margin-bottom: 0.5rem;
    }
  }

  @include desktop {
    & > .image {
      margin-top: rem(2);

      @include span-columns(2);

      & + .news {
        @include span-columns(10);
      }
    }
  }

  & > .news {
    time {
      display: block;
      margin-bottom: rem(4);
    }

    .title {
      font-weight: bold;
    }

    p {
      margin: 0.5rem 0 0;
    }
  }
}

.dk-box.director-box {
  h2 {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0.3rem;
  }

  .dk-box-content.dk-box-content {
    padding: 0 1rem 1rem;
  }

  .director-name {
    font-weight: bold;
    margin-bottom: 2rem;
  }
}

.chevron-stack {
  display: block;
  list-style: none;

  & > li {
    display: block;
    position: relative;
    margin-left: 0;
    border: solid 1px $divider-stroke;
    border-width: 0 0 1px;
    padding: 0.5rem rem(24) 0.5rem rem(5);

    &:first-child {
      border-top-width: 1px;
    }

    &::after {
      @extend %icon-base;
      content: $i-angle-right;
      display: block;
      position: absolute;
      right: rem(5);
      top: 50%;
      transform: translateY(-50%);
      color: $link-standard;
      font-size: 1.2em;
    }
  }
}

// Strategic Plans & Reports
.spr-detail {
  @extend %clearfix;

  & > :last-child {
    margin-bottom: 0;
  }
}

.spr-image {
  display: block;
  float: left;
  margin: 0.3rem 1rem 1rem 0;
  width: auto;
  height: auto;
  max-width: 40%;
  box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.13);
}

.spr-grid {
  margin: 0 -0.5rem;
  font-family: $open-sans;
  line-height: 1.3;

  @include row;

  &.spr-grid > * {
    margin: 1rem 0;
    padding: 0 0.5rem;
  }

  & > * {
    display: block;
    float: left;

    @include desktop {
      width: 25%;

      &:nth-child(4n+1) {
        clear: left;
      }
    }

    @include mobile {
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }

  .cover {
    box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.13);

    @include ratio(10, 13);

    img {
      display: block;
      position: absolute;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .title {
    margin-top: 0.5rem;
    font-weight: bold;

    &.line-clamp {
      @include line-clamp-grow(4);
    }
  }

  .date {
    margin-top: 0.5rem;
    font-size: rem(11);
  }

  a {
    &, &:visited, &:hover, &:active {
      text-decoration: none;

      .date {
        color: $text-general;
      }
    }

    &:hover, &:active {
      .title {
        text-decoration: underline;
      }
    }
  }
}

// Image card
.image-card {
  background-color: $bg-gray;
  font-family: $open-sans;

  // Duplicated class name to override dk-box styles
  &.image-card.image-card.image-card img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }

  & > :last-child {
    padding: rem(10);
  }
}

.image-cards.columns {
  @include desktop {
    overflow: hidden;

    .image-card {
      margin-bottom: -10rem;
      padding-bottom: 10rem;
    }
  }

  @include mobile {
    .image-card + .image-card {
      margin-top: 1rem;
    }
  }
}

// Links with an icon in front (email, phone)
%_text-icon-before {
  @extend %icon-base;
  @extend %icon-hover-fix;
  color: var(--icon-color, #{$text-general});
  padding: 0 rem(4) 0 rem(2);
}

%_text-icon-before-social-contact {
  @extend %_text-icon-before;
  display: block;
  position: absolute;
  padding: 0;
  font-size: 1.5em;
  top: 0;
  left: 0;
}

a {
  $class-icon: (
    email: $i-envelope-o,
    phone: $i-mobile
  );

  @each $cls, $icon in $class-icon {
    &.#{$cls}::before {
      @extend %_text-icon-before;
      content: $icon;
    }
  }

  &.no-icon:is(#{join-classes(map.keys($class-icon))})::before {
    content: normal;
  }
}

.social-contact-links {
  font-family: $open-sans;

  & > a {
    display: block;
    position: relative;
    max-width: 100%;
    margin-bottom: 0.8rem;
    padding-left: rem(24);

    $class-icon: (
      email: $i-envelope-square,
      phone: $i-phone-square,
      add-contact: $i-address-book,
      linkedin: $i-linkedin-square,
      researchgate: $i-researchgate-square,
      google-scholar: $i-google-scholar-square,
      orcid: $i-orcid-square
    );

    @each $cls, $icon in $class-icon {
      &.#{$cls}::before {
        @extend %_text-icon-before-social-contact;
        content: $icon;
      }
    }

    &.email {
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  @include desktop {
    & > a {
      margin-bottom: 0.5rem;
    }

    &.inline > a {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}

.bio-person-box {
  @extend %clearfix;
  @extend %accent-border;
  margin-bottom: 2rem;
  font-family: $open-sans;

  &, p {
    line-height: 1.5;
  }

  .details {
    margin-top: 1rem;
    @extend %remove-child-bookend-margins;

    .role {
      padding-left: 1rem;
      text-indent: -1rem;

      ul {
        list-style: none;
      }

      li {
        display: block;
        margin: 0;
        text-indent: -1rem;
      }

      * {
        text-indent: 0;
      }
    }

    & > div {
      margin: 1rem 0;
      @extend %remove-child-bookend-margins;
    }
  }

  .photo {
    margin-right: 1rem;

    img {
      display: block;
      max-width: rem(150);
      max-height: rem(200);
    }
  }

  .button {
    @extend %accent-background;
    @extend %accent-border;

    &:hover {
      @extend %accent-background-darken;
      @extend %accent-border-darken;
    }
  }

  .dk-tabnav {
    margin: 1rem -1rem 0;
  }

  .dk-no-tabnav {
    margin: 1rem 0 0;
  }

  @include desktop {
    & > div {
      display: flex;
    }

    .details {
      margin-top: 0;
    }
  }
}

dl.publications {
  line-height: 1.5;

  dt {
    margin: 0.4rem 0 0;
    font-weight: bold;
  }

  dd {
    margin-left: 0;

    p {
      line-height: inherit;
      margin: 0;
    }
  }

  dd + dt {
    margin-top: 1.5rem;
  }

  &.accented {
    dd, dt {
      border-left: solid 5px $block-accent-gray;
      padding-left: 1rem;
    }
  }
}

%od-img-circular {
  &.circle, .circle {
    img {
      border-radius: 100%;
    }
  }
}

.od-leaders {
  display: table;
  width: calc(100% + 1rem);
  margin: 1rem -0.5rem;
  border-spacing: 0.5rem 0;
  table-layout: fixed;

  @extend %od-img-circular;

  & > * {
    display: table-cell;
    text-align: center;
  }

  .image {
    max-width: rem(150);
    max-height: rem(150);
    margin: 0 auto 0.3rem;

    img {
      display: block;
    }
  }
}

.od-hierarchy {
  @extend %od-img-circular;
}

.odh-group {
  & > * {
    margin-bottom: 2rem;
    text-align: center;
  }

  .image {
    display: flex;
    margin-bottom: 0.3rem;
    justify-content: center;
    align-items: center;

    & > * {
      & + * {
        margin-left: rem(5);
      }
    }

    img {
      display: block;
      max-width: 100%;
      max-height: rem(150);
    }
  }

  @include desktop {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &.center {
      justify-content: center;
    }

    & > * {
      flex: 0 1 calc(50% - 0.5rem);
      width: calc(50% - 0.5rem);
    }

    .image {
      height: rem(150);

      &.circle > div {
        // Fix IE image sizing issue
        position: relative;
        width: 100%;
        height: 100%;

        & > img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    $ordering: (
      1: 1,
      2: 3,
      3: 5,
      4: 2,
      5: 4,
      6: 6
    );

    @each $i, $j in $ordering {
      & > :nth-child(#{$i}) {
        order: $j;
      }
    }
  }
}

svg {
  a {
    cursor: pointer;
  }
}

%mobile-swap-full {
  @include mobile {
    display: none;
  }
}

%mobile-swap-mobile {
  @include desktop {
    display: none;
  }
}

.mobile-swap {
  & > :not([class^="swap-"]):not([class*=" swap-"]) {
    &:first-child {
      @extend %mobile-swap-full;
    }

    &:last-child {
      @extend %mobile-swap-mobile;
    }
  }

  .swap-full {
    @extend %mobile-swap-full;
  }

  .swap-mobile {
    @extend %mobile-swap-mobile;
  }
}

.link-tiles {
  margin: 1rem 0;
  border-color: $default-accent-color;
  list-style: none;
  font-weight: $semibold;
  font-family: $open-sans;
  @extend %themable-border;

  &.accented > * {
    border-top-width: rem(8);
  }

  & > * {
    display: block;
    margin: 1rem 0;
    border: solid 1px;
    border-color: inherit;
  }

  a {
    display: block;
    padding: 2rem 0.5rem;
    text-align: center;
  }

  @include desktop {
    display: flex;
    margin-bottom: 0;
    flex-flow: row wrap;
    justify-content: space-between;

    &.link-tiles > * {
      margin: 0 1rem 1rem 0;
      width: calc(#{math.percentage(math.div(1, 3))} - #{math.div(2, 3)}rem - #{rem(1)});
    }

    & > :nth-child(3n) {
      margin-right: 0;
    }

    a {
      width: 100%;
      padding: 0;

      @include ratio(8, 5);

      & > span {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0.25rem;
        transform: translateY(-50%);
        text-align: center;
      }
    }

    &::after {
      content: '';
      display: block;
      width: calc(#{math.percentage(math.div(1, 3))} - #{math.div(2, 3)}rem - #{rem(1)});
    }
  }
}

.embed.facebook {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}

.left-image {
  &, * {
    @extend %remove-child-bookend-margins;
  }

  &.left-image.left-image {
    h2 {
      margin: 1rem 0;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    img {
      display: block;
      margin: 0;
      border: 0;
    }
  }

  .shadow img {
    box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.13);
  }

  @include desktop(true) {
    display: flex;
    align-items: flex-start;

    & > :first-child {
      flex: 0 0 auto;
      max-width: calc(30% - 1rem);
      height: auto;
      margin-right: 1rem;

      img {
        width: 100%;
      }
    }

    & > :last-child {
      flex: 1 1 auto;
    }
  }

  @include mobile {
    & > :first-child:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.float-mobile {
      @include clearfix;

      & > :first-child {
        float: left;
        max-width: calc(50% - 1rem);
        margin: rem(5) 1rem 0.5rem 0;
      }
    }
  }
}

.box-search {
  margin: 1rem 0;

  @include desktop {
    @at-root .dk-box &, .thin-box & {
      margin: 1rem;
    }
  }

  .search {
    @extend %standard-search-box;
  }

  .description {
    margin-top: 0.4rem;
    font: $semibold rem(12) $open-sans;
  }
}

html:not(.editmode) {
  @each $cls in (editor-only,meta-edit) {
    .#{""+$cls}.#{""+$cls}.#{""+$cls} {
      display: none;
    }
  }
}

// Language-specific content
@each $lang in $page-languages {
  body {
    &:not(.page-lang-#{$lang}) .lang-#{$lang},
    &.page-lang-#{$lang} .not-lang-#{$lang} {
      display: none;
    }
  }
}

// Contact Us buttons
.contact-us-buttons {
  .chat.online {
    display: none;
  }

  .button {
    display: block;
    min-width: 90%;
    font-size: 1.4rem;
    text-align: center;
    cursor: pointer;

    &.chat {
      &.online {
        background-color: $green;
        border-color: $green;

        &:hover {
          $darker: color.adjust($green, $lightness: -10%);

          background-color: $darker;
          border-color: $darker;
        }
      }

      &.offline {
        @extend %button;
        display: block;
        $gray: get-accent-color('gray', $prop: 'background-color');

        background-color: $gray;
        border-color: $gray;
        font-size: 1.4rem;

        &:hover {
          &:where(a) {
            $darker: color.adjust($gray, $lightness: -10%);

            background-color: $darker;
            border-color: $darker;
          }

          &:where(span) {
            background-color: $gray;
            border-color: $gray;
            cursor: default;
          }
        }
      }
    }

    &.email {
      $_lavender: get-accent-color('lavender', $prop: 'background-color');
      background-color: $_lavender;
      border-color: $_lavender;

      &:hover {
        $darker: color.adjust($_lavender, $lightness: -10%);

        background-color: $darker;
        border-color: $darker;
      }
    }

    &.tty {
      background-color: $orange;
      border-color: $orange;

      &:hover {
        $darker: color.adjust($orange, $lightness: -10%);

        background-color: $darker;
        border-color: $darker;
      }
    }


    & > i {
      margin-right: 0.8rem;
    }

    @include mobile {
      width: 100%;
    }
  }

  @at-root .livechat-online & {
    .chat {
      &.online {
        &.button {
          display: block;
        }
        &:not(.button) {
          display: inline;
        }
      }

      &.offline {
        display: none;
      }
    }
  }

  @include tablet {
    &.columns > div:first-child, &.columns > div:last-child {
      width: 100%;

      .button {
        min-width: rem(225);
        max-width: rem(300);
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
      }
    }
  }
}

.twitter-follow-wrap {
  margin: 1rem 0;
}

// Bootstrap-style "selling points" with small icon image and brief text
.selling-points {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1rem;
  text-align: center;
  font-family: $open-sans;

  & > * {
    flex: 1 0 rem(160);

    & > :first-child {
      margin-bottom: 1rem;
    }

    & > * + * {
      margin-top: 0.5rem;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: rem(70);
  }
}

.imagepop-hero {
  position: relative;
  padding-top: math.percentage(math.div(338, 660));
  overflow: hidden;

  .ih-grid {
    $imgWidth: math.percentage(math.div(302, 660));

    display: grid;
    position: absolute;
    grid-template: rem(8) #{1fr} / $imgWidth 1fr;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: $open-sans;
    font-weight: $semibold;

    & > * {
      background: transparent top left / cover no-repeat;
    }

    & > .bg-box {
      grid-area: 2 / 1 / span 1 / span 2;
      border-radius: rem(18);
    }

    & > .bg-img {
      grid-area: 1 / 1 / span 2 / span 1;
      padding-top: math.percentage(math.div(338, 302));
    }

    & > .content {
      grid-area: 2 / 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      @extend %remove-child-bookend-margins;

      & > * {
        margin: 0.5rem 0;
      }
    }

    .title {
      font-size: 1.3rem;
      font-size: clamp(1rem, 2vw, 1.5rem);
      line-height: 1.3;
    }

    a:not(.button) {
      color: $link-hover;
    }
  }

  @include mobile {
    padding: 0;

    .ih-grid {
      position: static;
      grid-template: #{rem(8)} auto auto / 1fr;

      & > .bg-box {
        grid-area: 2 / 1 / span 1 / span 1;
        border-radius: rem(18);
      }

      & > .bg-img {
        grid-area: 1 / 1 / span 2 / span 1;
        min-height: rem(160);
        padding-top: 50%;
        background-size: 60%;
        background-position: center top;
      }

      & > .content {
        grid-area: 3 / 1 / span 1 / span 1;
        margin: 1rem 0;
        padding: 0;
      }

      .title {
        font-size: 1.5rem;
      }
    }
  }
}

.imagepop-hero-note {
  font-family: $open-sans;
  font-weight: $semibold;

  @include desktop {
    text-align: right;
  }
}

.button {
  &.blue-green {
    background-color: $bg-blue-green;
    border-color: $bg-blue-green;

    &:hover {
      background-color: color.adjust($bg-blue-green, $lightness: -10%);
    }
  }

  @include mobile {
    &.mobile-full {
      display: block;
      width: 100%;
    }
  }
}

.social-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(#{rem(104)}, 1fr));
  gap: rem(10);
  margin: 1.5rem 0;
  font-weight: bold;
  font-family: $open-sans;

  &:not(.v2) {
    grid-template-columns: repeat(auto-fit, minmax(#{rem(154)}, 1fr));
    @extend %size-h4;
  }

  & > * {
    position: relative;
    overflow: hidden;
    border-radius: rem(3);
    box-shadow: $tile-shadow;

    img {
      display: block;
    }

    & > :last-child {
      display: block;
      padding: 0.5rem;
    }

    a:first-of-type:not(.eld)::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
