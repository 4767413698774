@use 'sass:math';
@use '../../tools' as *;
@use '../../tools/variables' as var;
@use '../../tools/icons';

@font-face {
  font-family: "Pragati Narrow-fallback";
  size-adjust: 92%;
  src: local("Arial Narrow");
}

/* pragati-narrow-regular - tailored */
@font-face {
  font-family: 'Pragati Narrow (TR pipeline)';
  font-style: normal;
  font-weight: 400;
  src: local('Pragati Narrow'),
       url('fonts/pragati-narrow-v13-trpipeline-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/pragati-narrow-v13-trpipeline-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0020, U+002d, U+0041-0044, U+0048-0049, U+004c-0050, U+0053-0055, U+0061-0069, U+006b-0070, U+0072-0076, U+0079-007a, U+2011;
}

/* pragati-narrow-700 - tailored */
@font-face {
  font-family: 'Pragati Narrow (TR pipeline)';
  font-style: normal;
  font-weight: 700;
  src: local('Pragati Narrow'),
       url('fonts/pragati-narrow-v13-trpipeline-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/pragati-narrow-v13-trpipeline-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0020, U+002d, U+0041-0044, U+0048-0049, U+004c-0050, U+0053-0055, U+0061-0069, U+006b-0070, U+0072-0076, U+0079-007a, U+2011;
}

$-pragati-narrow: "Pragati Narrow (TR pipeline)", "Pragati Narrow-fallback", "Arial Narrow", Arial, sans-serif;

// Translational research pipeline graphic
.tr-pipeline {
  margin: 1rem 0;

  a {
    &, &:visited, &:hover, &.current {
      color: inherit;
      text-decoration: none;
    }
  }

  ol {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0;
  }

  li {
    display: table-cell;
    list-style: none;
    vertical-align: top;
    text-align: center;
    font-family: $-pragati-narrow;
    font-size: rem(14);
    line-height: 1.2;
    margin: 0;
    padding: 0;

    .svgwrap {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: math.percentage(math.div(144, 223));
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    // Pipe graphics
    svg .water { fill: #3FD6FF; }
    svg .waves {
      fill: none;
      stroke: #534554;
      stroke-width: 2.0226;
      stroke-miterlimit: 10;
    }
    svg .glare { fill: #FFFFFF; }
    svg .flow { display: none; }
    svg .medium { fill: #A7B1B3; }
    svg .outline { fill: #534554; }
    svg .light { fill: #C7C9CB; }
    svg .dark { fill: #757C96; }
    svg .handle { fill: #D05F57; }
    &.current svg .medium { fill: #FF9E2F; }
    &.current svg .outline { fill: #7A4200; }
    &.current svg .light { fill: #FFBE74; }
    &.current svg .dark { fill: #CC6D00; }
    &.current svg .handle { fill: #D17000; }
    a:hover svg .medium { fill: #99C376; }
    a:hover svg .outline { fill: #366013; }
    a:hover svg .light { fill: #B4DE91; }
    a:hover svg .dark { fill: #689245; }
    a:hover svg .handle { fill: #6B9548; }
    &.current svg .flow, a:hover svg .flow { display: inline; }

    .bubble i::before {
      top: auto;
    }

    a:hover .bubble i, &.current .bubble i {
      background-color: #0F4C8C;
    }
  }

  .segment {
    position: relative;
    font-weight: bold;
  }

  li:not(:first-child):not(:last-child) {
    background-image: linear-gradient(0deg, #d6ecfa, var.$white 30%, var.$white 70%, #d6ecfa);

    .segment {
      background-image: linear-gradient(0deg, #d6ecfa, var.$white 35%, var.$white 65%, #d5e2cb);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: dashed 0 #949494 { //#B3B3B3 {
          left-width: 1px;
        }
      }
    }

    &:nth-last-child(2) .segment::before {
      border-right-width: 1px;
    }
  }

  .title-top, .title-bottom {
    font: {
      weight: bold;
      size: rem(14);
      family: Arial, sans-serif;
    }
    text: {
      align: center;
      transform: uppercase;
    }
    color: #0F4C8C;
    padding: rem(3);
    background-color: #d6ecfa;
    width: 40%;
    min-width: rem(215);
    margin: 0 auto;
  }

  .title-top {
    border-radius: rem(6) rem(6) 0 0;

  }

  .title-bottom {
    border-radius: 0 0 rem(6) rem(6);
    margin-top: -1px;
    padding-top: rem(4);
  }

  .starting-point, .step, .bubble, .bubble-text, .outcome {
    height: rem(50);
    overflow: hidden;
  }

  .starting-point, .step {
    padding-top: rem(8);
  }

  .step {
    color: #0F4C8C;
  }

  .bubble-text {
    margin-top: rem(-5);
    margin-right: rem(-5);
  }

  .bubble {
    margin-bottom: rem(-5);
    overflow: hidden;
    font-weight: normal;

    i {
      display: inline-block;
      color: var.$white;
      background-color: #646569;
      width: rem(50);
      height: rem(50);
      font-size: rem(46);
      border: solid 2px var.$white {
        radius: 50%;
      }
      text-align: center;
      line-height: math.div(5, 4);
    }
  }

  .outcome {
    height: rem(60);
    padding: rem(5) 0;
  }

  .mnav {
    &, .n, .p {
      display: none;
    }
  }

  @include breakpoint($max: var.$variable-layout-max) {
    .title-top, .title-bottom, .starting-point, li:not(.prev):not(.current):not(.next):not(.show) {
      display: none;
    }

    ol {
      width: 252px;
      max-width: 100%;
      margin: 0 auto;
    }

    li:not(:last-child) {
      &.next:not(.mid), &.show {
        .segment::before {
          border-right-width: 1px;
        }
      }
    }

    .mnav {
      display: block;
      height: 22px;
      background-color: #949494;
      color: var.$white;
      font: {
        weight: normal;
        family: var.$open-sans;
        size: 13px;
      }
      line-height: math.div(22, 13);
      text-shadow: 1px 1px 1px var.$black;

      .p {
        text-align: left;

        &::before {
          @include icons.icon-base;
          content: icons.$i-angle-left;
          position: relative;
          top: 1px;
          margin: 0 0.2rem;
        }
      }

      .n {
        text-align: right;

        &::after {
          @include icons.icon-base;
          content: icons.$i-angle-right;
          position: relative;
          top: 1px;
          margin: 0 0.2rem;
        }
      }
    }

    .next.mid .mnav .n {
      text-align: center;
      padding-left: 5px;
    }

    .next .mnav .n, .prev .mnav .p {
      display: block;
    }
  }
}

@include breakpoint($max: var.$variable-layout-max) {
  .tr-full-only {
    display: none;
  }
}

@include breakpoint($min: var.$fixed-layout-min) {
  .tr-small-only {
    display: none;
  }
}

.tr-list {
  li {
    list-style: none;
    margin: .5rem 0;
    padding: 0;
  }

  a:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    i {
      background-color: #0F4C8C;
    }
  }

  i {
    display: inline-block;
    color: var.$white;
    background-color: #646569;
    width: rem(50);
    height: rem(50);
    margin-right: 0.5rem;
    font-size: rem(46);
    border-radius: 50%;
    text-align: center;
    line-height: math.div(5, 4);
    vertical-align: middle;
  }
}
