@use '../tools' as *;

// Feature box extensions specifically for NIDDK
.dk-box {
  $hp-accent-colors: (
    'hp-diabetes':  #54b7ab,
    'hp-digestive': #45a9ba,
    'hp-kidney':    #636e8f,
    'hp-weight':    #ba7c05
  );

  @each $class, $color in $hp-accent-colors {
    &.#{$box-border-prefix}thin, &.#{$box-border-prefix}thin-accent {
      &.#{$accent-color-prefix}#{$class} {
        border-color: $color;
      }
    }
  }

  // Content classes
  .dk-box-content {
    .fb-list {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-spacing: rem(5) 0;

      figure {
        margin: 0 auto;
      }

      h4 {
        margin-bottom: 0.5rem;
      }

      & ul {
        margin: {
          top: 0;
          bottom: 0;
        }
      }

      &.half-width {
        @include span-columns(6 of 12);
        ul {
          @include span-columns( 3 of 6 );
          @include mobile {
            @include span-columns( 12 of 12 );
          }
        }
      }

      &.full-width {
        ul {
          @include span-columns(12 of 12);
        }
      }

      div {
        display: table-cell;
        vertical-align: top;

        @include mobile {
          @include span-columns(12 of 12);

          li:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    ul.media {
      $spacing: 8;

      display: table;
      width: calc(100% + #{rem(2 * $spacing)});
      border-spacing: rem($spacing);
      margin: rem(-1 * $spacing);

      & > li {
        display: table-row;

        & > * {
          display: table-cell;
          vertical-align: middle;
        }
      }

      .i-audio, .i-video, .audio, .video {
        font-size: 1.5rem;
        color: $text-general;
      }

      a.i-audio, a.i-video, a.audio, a.video {
        &:hover, &:active {
          color: $text-dark;
        }
      }

      .audio {
        @extend %icon-base;

        &::before {
          content: $i-audio;
        }
      }

      .video {
        @extend %icon-base;

        &::before {
          content: $i-video;
        }
      }
    }

    .blog-item {
      margin-bottom: 1.5rem;

      .blog-date {
        font-weight: $semibold;
        font-size: rem(12);
      }
    }
  }
}
