@use '../tools' as *;

// Ensure that page theming is enabled
@include enable-themable-pages;

// Previous/next navigation links (e.g. disease suite navigation)
.previous-next {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin: 3rem 0;
  font-family: $open-sans;
  line-height: 1.7;
  border: solid 0 $button-outline;
  border-width: rem(2) 0;

  &.no-border {
    border: 0;
  }

  @include mobile {
    font-size: rem(12);
  }

  & > * {
    display: none;
    padding: 1rem 0.5rem;
    overflow: hidden;
    vertical-align: middle;
    border: solid 0 $button-outline;

    &:first-child, &:last-child {
      display: table-cell;
      width: 50%;
    }

    &:first-child {
      border-right-width: 2px;

      & > div {
        padding-left: rem(35);
        margin-left: -0.5rem;

        &::before {
          content: $i-angle-left;
          left: rem(3);
        }

        @include mobile {
          padding-left: rem(18);

          &::before {
            left: 0;
          }
        }
      }
    }

    &:last-child {
      border-left-width: 2px;
      text-align: right;

      & > div {
        padding-right: rem(35);
        margin-right: -0.5rem;

        &::before {
          content: $i-angle-right;
          right: rem(3);
        }

        @include mobile {
          padding-right: rem(18);

          &::before {
            right: 0;
          }
        }
      }
    }

    & > div {
      display: block;
      position: relative;

      &::before {
        @extend %prev-next-icon;
      }
    }

    em, strong {
      display: block;
      font: {
        style: normal;
        weight: bold;
      }
    }
  }

  & > a {
    &, &:visited, &:hover {
      color: $text-general;
    }

    &:hover {
      background-color: $bg-ash;
      text-decoration: none;
    }
  }

  &.themed > * > div::before {
    @extend %themable-forecolor;
  }
}

%prev-next-icon {
  @extend %icon-base;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
  @extend %accent-foreground;

  @include mobile {
    font-size: 2rem;
  }
}
