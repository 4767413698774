@use '../tools' as *;

// Set default box sizing
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

// Base font size (1rem)
html, body {
  margin: 0;
  font-size: $em-base;

  @if $tablet-scale and $tablet-scale != 1 {
    @include tablet {
      font-size: $em-base * $tablet-scale;
      -webkit-text-size-adjust: 100%;
    }
  }
}

// Body color (page background)
body {
  color: $text-dark;
  background-color: $bg-outer-body; // #eeeded
  font-family: $font-sans;
}

.grid {
  @extend %dk-grid;
}

.dk-stripe {
  display: block;

  &:not(.overflow) {
    @extend %clearfix;
  }
}

.float-left {
  display: block;
  float: left;
}

.float-right {
  display: block;
  float: right;
}
