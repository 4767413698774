@use '../tools' as *;

.dk-tabnav {
  $shadow-size: 5px;

  position: relative;
  z-index: 5;
  margin: 1rem 0 0;
  padding: 0 1rem;
  border-color: inherit;
  background-color: $bg-tab-nav; // Standard gray for when this appears on a white background

  // Apply this background color when this element appears within the lab/branch or section headers
  &.translucent {
    background-color: rgba($white, 0.6);
  }

  .tn-drop-button {
    display: inline-block;
    position: relative;
    min-width: rem(200);
    max-width: 100%;
    height: 2.5rem;
    padding-left: rem(30);
    margin-bottom: 0.4rem;
    border: 1px solid $button-outline;
    border-radius: rem(3);
    background-color: $white;
    color: $text-dark;
    font-family: $open-sans;
    font-weight: $semibold;
    font-size: 1rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    appearance: none;

    &::before {
      @extend %icon-base;
      content: $i-angle-down;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: rem(23.5);
      transform: translateY(-50%);
      padding: rem(5) 0;
      color: $link-standard;
      text-align: right;
    }
  }

  & > ul {
    @extend %clearfix;
    @extend %unselectable;

    list-style: none;
    margin: 0;
    padding: 0;
    font-family: $open-sans;
    font-weight: $semibold;
    color: $text-dark;

    & > li > ul {
      list-style: none;
      margin: 0;
      font-weight: normal;

      & > li {
        margin: 0;

        & > a, & > span {
          display: block;
          padding: 0.3rem 1rem;
        }
      }

      span {
        cursor: default;
      }

      ul {
        list-style-type: disc;

        a {
          display: block;
        }
      }

      // Unnecessary?
      ul ul {
        list-style-type: circle;
        margin-left: 0.3rem;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include mobile-tablet {
    padding: 0.75rem 1rem;

    &:not(.tn-menu-open) > ul {
      display: none;
    }

    & > ul {
      display: block;
      position: absolute;
      left: rem(15);
      min-width: rem(198);
      max-width: calc(100% - 2rem);
      border-radius: rem(3);
      box-shadow: 0 2px $shadow-size gray;
      background-color: $white;

      &::before {
        content: $i-triangle-up;
        display: block;
        position: absolute;
        top: rem(-19);
        left: rem(4);
        width: rem(30);
        height: rem(30);
        color: $white;
        font-size: rem(30);
        font-family: $dk-icons;
        line-height: 1;
        text-rendering: auto;
        text-shadow: 0 -1px 0 $button-outline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.tn-expandable {
        button {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: rem(35);
          border: 0;
          border-left: solid 1px $button-outline;
          padding: 0;
          background-color: transparent;
          appearance: none;
          color: inherit;
          font-size: 0;
          font-weight: inherit;
          cursor: pointer;

          &::before {
            font-size: 1rem;
            line-height: 1.5;
          }
        }

        & > li > div {
          position: relative;
          padding-right: rem(35);
        }
      }

      .tn-collapsed > ul {
        display: none;
      }

      li.tn-current {
        & > :first-child {
          background-color: $ln-current-child;
        }

        &:first-child {
          &, & > :first-child {
            border-radius: rem(3) rem(3) 0 0;
          }
        }

        &:last-child {
          &, & > :first-child {
            border-radius: 0 0 rem(3) rem(3);
          }
        }
      }

      a, span {
        display: block;
        padding: 0.5rem;
      }

      & > li, & > li > ul > li {
        display: block;
        margin: 0;
      }

      & > li {
        border-top: solid 1px $button-outline;

        &:first-child {
          border: 0;
        }

        & > ul {
          padding: 2px 0;
          box-shadow: inset 0 -2px 2px -2px gray, inset 0 5px 5px -5px gray;

          & > li {
            & > a {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }

            ul {
              margin: 0 0 0 1rem;
            }

            // Necessary?
            ul ul {
              margin-left: 0;
            }

            li {
              margin: 0 0 0 1.3em;

              a {
                padding-left: 0;
                margin-left: -0.5em;
              }
            }
          }
        }
      }
    }
  }

  @include desktop-only {
    .tn-drop-button {
      display: none;
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;
      border-color: inherit;

      &.tn-expandable > li > div > :first-child {
        display: flex;
        align-items: center;

        &::after {
          @include icon-base;
          content: $i-chevron-down;
          display: block;
          padding-left: 0.3em;
          transform: translateY(2px);
          font-size: 0.8em;
        }
      }
    }

    & > ul > li {
      display: block;
      position: relative;
      margin: 0;
      border-color: inherit;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      & > ul {
        position: absolute;
        top: calc(100% - 2px);
        z-index: 1;
        min-width: rem(250);
        padding-bottom: 0.25rem;
        border-color: $divider-stroke;
        border-radius: 0 rem(3) rem(3);
        background-color: $white;

        &.tn-align-right {
          right: 0;
        }

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 0.5rem;
          position: absolute;
          top: 100%;
        }
      }

      & > a, & > span, & > div > a, & > div > span {
        display: flex;
        align-items: center;
        padding: (0.5rem - rem(3)) 0.5rem 0.5rem;
        border-top: solid rem(3) transparent;
        text-align: center;
      }

      & > a:hover, & > div > a:hover {
        text-decoration: none;
      }

      & > div > :not(:first-child) {
        display: none;
      }

      &.tn-current, &:hover, &.focus {
        & > :first-child {
          &, & > a, & > span {
            border-top-color: inherit;
            background-color: $white;
          }
        }
      }

      // White line covering bottom border to connect the current tab to the content area
      &.tn-current::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        border-top: solid 1px $white;
      }

      &:hover, &.focus {
        & > :first-child::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 4px;
          z-index: 2;
          background-color: $white;
        }

        & > * {
          box-shadow: 0 2px $shadow-size gray;
        }

        & > span:first-child:last-child {
          box-shadow: none;
        }

        & > a:first-child:last-child {
          box-shadow: (-1 * $shadow-size) 0 $shadow-size (-1 * $shadow-size) gray, $shadow-size 0 $shadow-size (-1 * $shadow-size) gray;
        }

        & > ul {
          display: block;
        }
      }

      & > ul {
        display: none;
      }
    }

    &.single-row > ul {
      flex-wrap: nowrap;
      align-items: flex-end;

      & > li {
        margin: 0;

        & + li {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
