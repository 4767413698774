@use 'sass:color';
@use '../tools' as *;

$site-nav-default-timing:  250ms ease-out !default;

@mixin dk-site-nav-desktop($color: #213882, $current-color: #1365b1) {
  $timing:        $site-nav-default-timing;
  $divider-color: #d5d5d6;
  $grad-main:     linear-gradient(180deg, #{color.mix(#000, $color, 6.6%)} 18%, $color 25%, $white 75%);
  $grad-current:  linear-gradient(180deg, #{color.mix(#000, $current-color, 1.8%)} 7%, $current-color 25%, $current-color 77%, $white 77%);
  $grad-between:  linear-gradient(180deg, #{color.mix(#000, $site-nav, 32%)}, #{color.mix(#fff, $color, 32%)} 25%, $divider-color 75%);
  $csindicator-size: 6px;

  display: block;
  position: relative;
  width: 100%;
  z-index: 30;
  box-shadow: 0 0 0 transparent;

  &:not(.no-transition) {
    transition: box-shadow #{$timing}, background-position-y #{$timing};

    .sn-sections, .sn-section {
      transition: background-position-y #{$timing};
    }

    .sn-section-title > a {
      transition: color #{$timing};

      span {
        transition: text-shadow #{$timing}, font-size #{$timing};
      }

      &::after {
        transition: border-color #{$timing};
      }
    }
  }

  &, .sn-section, .sn-sections {
    background: $white $grad-main 0% 100% / 1px #{rem(224)} repeat-x;
  }

  .sn-sections {
    background-color: $divider-color;
    background-image: $grad-between;
  }

  .sn-section.current {
    background-color: $current-color;
    background-image: $grad-current;
  }

  &, .sn-section, .sn-sections {
    background-position-y: 0%;
  }

  .sn-section-title > a {
    color: $white;

    span {
      text-shadow: rem(1.5) rem(2.598) rem(9) rgba(0, 0, 0, 0.24);
    }
  }

  // Main styling
  .sn-bar {
    display: flex;
    width: calc(100% + 2px);
    transform: translateX(-1px);
  }

  .sn-home {
    display: none;
  }

  .sn-sections-wrap {
    flex: 1 1 auto;
  }

  .sn-sections {
    display: table;
    width: 100%;
    //border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 1px 0;
    font-family: $font-sans-alt;
  }

  .sn-section {
    display: table-cell;
    vertical-align: top;
    //border: solid 0 transparent;
    //border-width: 0 1px;
    //background-clip: content-box;

    &.focused, &:hover {
      .sn-section-title > a {
        color: $text-dark;
        background-color: rgba($white, $site-nav-opacity);

        span {
          text-shadow: none;
        }

        &::before {
          visibility: visible;
        }

        &::after { // Current section indicator
          @include triangle("up", rem($csindicator-size * 2), rem($csindicator-size), $text-general);
        }
      }
    }

    &.current {
      background-image: $grad-current;

      .sn-section-title > a::after { // Current section indicator
        content: '';
      }
    }

    .sn-chk-expand, .sn-lbl-expand {
      display: none;
    }
  }

  .sn-section-title > a {
    display: block;
    position: relative;
    z-index: 31;
    min-width: rem(100);
    margin-top: rem(4);
    line-height: rem(52);
    text-align: center;

    span {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-size: 1.3rem;
      padding: 0 rem(3);
      line-height: 1.05em;
      vertical-align: middle;
    }

    &::before { // Shadow
      $shadow-size: 20px;

      content: '';
      position: absolute;
      visibility: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: (-1 * $shadow-size) 0 (2 * $shadow-size) (-1 * $shadow-size) $black, $shadow-size 0 (2 * $shadow-size) (-1 * $shadow-size) $black;
      clip: rect(-2rem, 100rem, #{rem(52)}, -100rem);
    }

    &::after { // Current section indicator
      @include triangle("up", rem($csindicator-size * 2), rem($csindicator-size), $bg-outer-body);

      display: block;
      visibility: visible;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .sn-subsections {
    display: none;
  }
}

@mixin dk-site-nav-desktop-dropdown($hover-delay: 250ms) {
  $timing: $site-nav-default-timing;

  &:not(.no-hover-delay) {
    $delay-timing: 10ms linear #{$hover-delay};

    .sn-section .sn-section-title a {
      transition: all #{$delay-timing};

      span {
        transition: text-shadow #{$delay-timing}, font-size #{$delay-timing};
      }

      &::before {
        transition: visibility #{$delay-timing};
      }

      &::after { // Current section indicator
        transition: border-color #{$delay-timing};
      }
    }

    .sn-subsections {
      transition: all #{$delay-timing};
    }
  }

  &.stuck .sn-section {
    @include desktop-only {
      &:not(.no-child) {
        .sn-section-title a span::after {
          @include icon-base;
          content: $i-angle-down;
          vertical-align: top;
          color: $default-accent-color;
          padding-left: 0.5rem;
          font-size: 1.3rem;
          line-height: inherit;
        }
      }
    }
  }

  .grid {
    position: relative;
    z-index: 5;
  }

  .sn-section {
    &.focused, &:hover {
      .sn-section-title > a::after { // Current section indicator
        border-color: transparent;
      }

      .sn-subsections {
        visibility: visible;
      }
    }

    // .sn-chk-expand {
    //   &:checked {
    //     // & + .sn-lbl-expand {

    //     // }

    //     & ~ .sn-subsections {
    //       visibility: visible;
    //     }
    //   }
    // }
  }

  .sn-subsections {
    $nav-drop-cols: 18;

    display: block;
    position: absolute;
    visibility: hidden;
    z-index: 30;
    background-color: rgba($white, $site-nav-opacity);
    color: $text-dark;
    top: 100%;
    left: 1px;
    right: 1px;
    padding: rem(28) rem(21);
    box-shadow: 0 rem(13.5) rem(24.5) rem(5) rgba($black, 0.24);
    //box-shadow: 0 rem(7) rem(21) rem(7) rgba($black, 0.24);
    transition: background-color #{$timing};

    a {
      display: block;
      color: $text-dark;
      font-size: 0.97rem;
      text-decoration: none;
      padding: 0.6em 0;
      font-family: $font-sans-alt;

      &:hover {
        text-decoration: underline;
      }
    }

    .sn-collapsible {
      display: flex;

      & > div {
        border-right: solid 1px $divider-stroke;
        flex: 1;
        padding: 0 1rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: 0;
          padding-right: 0;
        }

        &.sn-subgroup {
          /*width: rem(150) + 1rem;
          padding-left: 0.5rem;
          flex: 0 1 auto;

          &:first-child {
            padding-left: 0;
            border-right: 0;
          }*/
          width: rem(330);
          flex: 0 1 auto;
          columns: 2;
        }

        .sn-subgroup-title {
          display: none;
        }
      }
    }
  }
}

@mixin dk-site-nav-desktop-sticky {
  $timing: $site-nav-default-timing;

  &:not(.no-transition) {
    .sn-home {
      transition: width #{$timing};
    }

    &.stuck .sn-home {
      transition: width 400ms ease-out, min-width 400ms ease-out;
    }
  }

  .sn-home {
    display: block;
    width: 0;
    min-width: 0;
    height: rem(56);
    overflow: hidden;

    .sn-section-title {
      display: none;
    }

    a {
      display: block;
      width: rem(150);
      height: 100%;
      margin: rem(4) 0.5rem 0 0;
      overflow: hidden;

      &.dk-logo {
        text-indent: -5000em;
        background: url(../images/site-logos.svg#abbrev) no-repeat 0 50%;
      }

      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.stuck {
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 1px rem(10) $medium-gray;

    .sn-section-title {
      display: block;
    }

    & ~ .balloon {
      width: 100%;
      height: rem(56);
    }

    &, .sn-section, .sn-sections {
      background-position-y: 100%;
    }

    .sn-section {
      &.current .sn-section-title > a::after {
        content: normal;
      }

      .sn-section-title > a {
        color: $text-dark;

        span {
          font-size: 1rem;
          text-shadow: rem(1.5) rem(2.598) rem(9) transparent;
        }
      }
    }

    .sn-home {
      width: 20%;
      min-width: rem(150) + 0.5rem;
    }

    .sn-subsections {
      background-color: $white;
    }
  }
}

@mixin dk-site-nav-mobile {
  $bg-parent: #282828;
  $bg-sibling: #282828;
  $bg-current: #3e4146;
  $bg-children: #313131;
  $bg-children-current: #343539;
  $bg-expand: #313131;
  $bg-expand-current: #414349; //$bg-expand;
  $text-parent: $white;
  $text-normal: #f5f5f5;
  $text-glyph: #ccc;
  $divider-stroke: #464646;
  $sub-divider-stroke: #464646;
  $button-width: 45;
  $h-pad: 16;
  $v-pad: 15;

  position: fixed;
  left: 100%;
  top: 0;
  width: calc(100% - #{rem(40)});
  height: 100%;
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 111;
  transition: left 0.5s;
  list-style: none;
  background: #111;
  overflow-y: scroll;
  font-family: $open-sans;
  font-size: rem(14);

  @at-root .site-wrap.pushed & {
    left: rem(40);
  }

  @at-root .site-wrap {
    right: 0;
    transition: right 0.5s;

    &.pushed {
      right: calc(100% - #{rem(40)});
      position: relative;

      .page-mask {
        @include page-mask(110);

        & > .sn-close-nav {
          visibility: visible;
          top: 0;
          transition: top 0.25s 0.3s;
        }
      }
    }
  }

  @at-root .site-wrap .page-mask > .sn-close-nav {
    $bg-parent: #282828;
    $text-glyph: #ccc;

    display: block;
    position: fixed;
    visibility: hidden;
    top: rem(-40);
    left: 0;
    z-index: 50;
    width: rem(40);
    height: rem(40);
    color: $text-glyph;
    background: $bg-parent;
    border: 0;
    border-right: solid 1px #464646;
    transition: top 0.25s, visibility 0s 0.25s;
    text-align: center;
    appearance: none;

    i {
      font-size: rem(20);
      line-height: 2;
    }
  }

  %mobile-site-nav-expand {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: rem($button-width);
  }

  .sn-bar {
    background: $bg-sibling;

    &.sn-expandable > li > :first-child:not(div) {
      width: calc(100% - #{rem($button-width)});

      &::after {
        @extend %mobile-site-nav-expand;
      }
    }
  }

  .sn-home, .sn-section {
    border-bottom: solid 1px $divider-stroke;
  }

  .sn-section-title {
    display: block;
    position: relative;
    line-height: 1.4em;

    a {
      display: block;
      padding: rem($v-pad) rem($h-pad) rem($v-pad) rem($h-pad - 5);
      border-left: solid rem(5) $bg-parent;
      font-size: rem(16);

      &:hover, &:active {
        text-decoration: none;
        background: color.mix(#000, $bg-sibling, 10%);
      }
    }
  }

  .sn-section {
    position: relative;

    span {
      color: $white;
    }

    &.current {
      .sn-section-title > a {
        border-left-color: $text-glyph;
        //background: $bg-current;
      }

      // .sn-collapsible > div > div {
      //   //background: $bg-children-current;
      // }

      // %mobile-site-nav-expand {
      //   //background: $bg-expand-current;
      // }
    }

    .sn-chk-expand {
      position: absolute;
      top: -1000em;
      left: -1000em;

      & + .sn-section-title {
        border-bottom: 0 solid $divider-stroke;
        transition: border-bottom-width 0s 0.3s;

        a {
          width: calc(100% - #{rem($button-width)});
        }

        label {
          @extend %mobile-site-nav-expand;
          border: 0;
          background: $bg-expand;
          font-size: 0;
          text-indent: 100em;
          cursor: pointer;

          &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(16);
            height: rem(4);
            transform: translate(-50%);
            border-radius: 2px;
            background-color: $text-glyph;
          }

          &::after {
            transform: translate(-50%) rotate(90deg);
            opacity: 1;
            transition: all 0.3s;
          }
        }
      }

      &:checked + .sn-section-title {
        border-bottom-width: 1px;
        transition: border-bottom-width 0s;

        label::after {
          transform: translate(-50%);
          //width: 0;
          opacity: 0;
        }
      }

      & ~ .sn-subsections {
        display: flex;
        overflow: hidden;

        &::after {
          content: '';
          height: 0;
          transition: height 0.3s ease;
          max-height: 50px;
        }

        .sn-collapsible {
          margin-bottom: -2000px;
          transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1),
                      visibility 0s 0.3s, max-height 0s 0.3s;
          visibility: hidden;
          max-height: 0;
        }
      }

      &:checked ~ .sn-subsections {
        $v-pad: 4;

        &::after {
          height: 50px;
          transition: height 0.3s ease, max-height 0s 0.3s ease;
          max-height: 0;
        }

        .sn-collapsible {
          transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
          margin-bottom: 0;
          visibility: visible;
          max-height: 1000000px;
        }
      }
    }

    &.sn-collapsed {
      & > ul {
        display: none;
      }

      button::before {
        content: $i-plus;
      }
    }
  }

  .sn-collapsible {
    width: 100%;

    & > div > div {
      border-bottom: 1px solid $sub-divider-stroke;
    }

    & > div:last-child, & > .sn-subgroup {
      & > div:last-child {
        border-bottom: 0;
      }
    }
  }

  .sn-subsections {
    $v-pad: 4;

    background: $bg-children;

    a {
      display: block;
      padding: rem(12) 0.5rem {
        left: 1.75rem;
      }
      font-size: rem(13);
      line-height: 1.4;
    }
  }

  a {
    text-decoration: none;
    color: $text-normal;
  }

  .sn-subgroup {
    padding: 0;
    margin-left: 1rem;
    border: {
      left: solid 0.75rem $bg-sibling;
      bottom: 0;
    }

    & > :first-child {
      color: $white;
      text-transform: uppercase;
      font-size: 0.8em;
      background: $bg-sibling;
      line-height: 1.5em;
      padding: rem(8) 0;
    }

    & > :not(:first-child) {
      margin-left: 0.5rem;

      a {
        padding: rem(12) 0.5rem;
      }
    }

    & + div {
      border-top: solid 1px $divider-stroke;
    }
  }
}
