@use 'sass:color';
@use 'sass:math';
@use '../tools' as *;

.context-menu-button {
  position: absolute;
  bottom: 0;
  margin-left: rem(10);
  padding: rem(5);
  border: 0;
  background-color: #c2d8ed;
  color: $black; //color from leftnav scss for current item
  font-family: $open-sans;
  font-size: rem(14);

  @include desktop {
    display: none;
  }

  &::after, &.open {
    background-color: $site-nav-current;
    color: $white;
  }

  &::after {
    @extend %icon-base;
    content: $i-angle-down;
    width: 1rem;
    margin-left: rem(5);
    border-radius: rem(3);
    text-align: center;
  }

  &.open {
    bottom: rem(-18);
    border-bottom: rem(18) solid $site-nav-current;

    &::after {
      content: $i-angle-up;
    }
  }
}

.dk-main-container .dk-leftnav {
  $divider-stroke: #d2d2d2;
  $bg-parent: $ln-parent; // #294484
  $bg-sibling: $white; // #fff
  $bg-current: $ln-current; // #c2d8ed
  $bg-children: #f6f6f9;
  $bg-children-current: $ln-current-child; // #f2f6fb
  $bg-expand: $button-gray; // #f5f5f5
  $bg-expand-current: #dae3ed;
  $bg-group: $divider-stroke;
  $bg-group-children: $bg-expand;
  $text-parent: $white; // #fff
  $text-normal: #343638;
  $text-glyph: #888;
  $button-width: 45;
  $h-pad: 10;
  $v-pad: 10;

  font: {
    size: rem(14);
    family: $open-sans;
  }

  @include mobile {
    &.dk-leftnav {
      display: none;
      position: absolute;
      z-index: 50;
      left: 0;
      right: 0;
      width: 100%;
      border: rem(10) solid $site-nav-current;
      margin: 0;

      &.related {
        display: block;
      }
    }
  }

  & > a:first-of-type {
    display: block;
    padding: rem($v-pad) rem($h-pad);
    font: {
      family: $montserrat;
      size: rem(18);
    }
    line-height: 1.3;
    color: $text-parent;
    background: $bg-parent;

    &, &:visited {
      color: $text-parent;
    }
    &:hover, &:active {
      text-decoration: none;
      background: color.mix(#000, $bg-parent, 20%);
    }
  }

  & > ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    background: $bg-sibling;

    &.ln-expandable > li:not(.ln-group) > :first-child:not(div) {
      width: calc(100% - #{rem($button-width)});

      &::after {
        @extend %expand;
      }
    }

    & ul {
      margin: 0;
    }
  }

  & > ul > li {
    display: block;
    margin: 0;
    border-bottom: 1px $divider-stroke solid;

    &, & > div {
      position: relative;
    }

    & > :first-child:not(div), & > div :first-child {
      display: block;
      padding: rem($v-pad) rem($h-pad);
      line-height: 1.4;
    }

    & > a, & > div a {
      &:hover, &:active {
        text-decoration: none;
        background: color.mix(#000, $bg-sibling, 10%);
      }
    }

    &.ln-group {
      background-color: $bg-group-children;

      & > :first-child {
        display: block;
        background-color: $bg-group;
        width: auto;
        font: {
          weight: bold;
          size: rem(13);
        }
        padding: rem(7) {
          left: rem(24);
        }

        &::after {
          content: none;
        }

        & > * {
          display: inline-block;
          padding: 0;
          width: 100%;
          text-align: left;
          color: $text-dark;
        }
      }

      & > :first-child:not(div)::after {
        @extend %icon-base;
        content: $i-angle-up;
        left: rem(5);
      }

      &.ln-collapsed button::after {
        content: $i-angle-down;
      }

      button {
        border: 0;
        background: transparent;
        cursor: pointer;
        padding-right: rem(15);
        font: {
          size: rem(13);
          family: $open-sans;
          weight: bold;
        }
        line-height: 1.4;

        &::after {
          @extend %icon-base;
          content: $i-angle-up;
          left: rem(5);
        }
      }

      ul {
        list-style: none;
        border-left: solid rem(14) $bg-group;
        padding: 0;

        li {
          margin: 0;
          padding: rem(2) rem(10);
          border-bottom: solid 1px $bg-group;
          font-size: rem(13);

          &:last-of-type {
            border: 0;
          }
        }
      }
    }

    %expand {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: rem($button-width);
      margin: 0;
      background: $bg-expand;
    }

    &.ln-current {
      & > :first-child, & > div :first-child {
        background: $bg-current;
      }

      ul {
        background: $bg-children-current;
      }

      &:not(.ln-group) button {
        background: $bg-expand-current;
      }
    }

    & > div :first-child {
      width: calc(100% - #{rem($button-width)});
    }

    &:not(.ln-group) button {
      @extend %expand;
      border: 0;
      cursor: pointer;
      text-indent: -5000em;
      overflow: hidden;

      &::after {
        $size: 18;

        @include icon-base;
        content: $i-minus;
        font-size: rem($size);
        text-indent: 0;
        color: $text-glyph;
        display: block;
        position: absolute;
        width: rem($size);
        height: rem($size);
        top: calc(50% - #{rem(math.div($size, 2))});
        left: calc(50% - #{rem(math.div($size, 2))});
      }
    }

    &.ln-collapsed {
      ul {
        display: none;
      }

      button::after {
        content: $i-plus;
      }
    }
  }

  ul ul {
    $v-pad: 4;

    list-style: outside disc;
    padding: rem($v-pad) 0 rem($v-pad) rem($h-pad);
    background: $bg-children;
    border-top: 1px solid $divider-stroke;

    li.ln-current {
      font-weight: bold;
    }

    a {
      display: block;
      padding: rem($v-pad) rem($h-pad);
      padding-left: 0;
      line-height: 1.4em;
    }
  }

  a {
    color: $text-normal;
  }
}
