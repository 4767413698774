@use 'sass:color';
@use 'sass:math';
@use '../tools' as *;
@use 'main';

main {
  & > section {
    padding: rem(10) 0;
  }

  // list styles
  @at-root {
    .dk-tabs {
      li {
        margin: 0;
        list-style-position: initial;
      }

      ul, ol {
        margin-left: 1rem;
      }
    }
  }

  ul, ol {
    &.no-bullets li, &.no-bullets-shallow > li {
      @extend %remove-bookend-margins;
      list-style: none;
      margin: 0.7em 0;
    }

    &.no-bullets-shallow {
      & > li li {
        list-style-type: disc;
      }

      & > li li li {
        list-style-type: circle;
      }
    }

    &.loose li, &.loose-shallow > li {
      @extend %remove-bookend-margins;

      &, & > ul, & > ol {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }

    &.float-fix li {
      position: relative;
      overflow: hidden;
      margin-left: 0;
      padding-left: 1.3em;

      &::before {
        content: '\2022';
        position: absolute;
        top: 0.15em;
        left: 0;
        font-size: rem(25);
        line-height: math.div(14, 25);
      }
    }
  }

  .align-center {
    text-align: center;
  }

  %pull-quote {
    &, &.center {
      display: block;
      float: none;
      border: 0 solid $default-accent-color;
      border-left-width: rem(7);
      margin: 1rem 0;
      padding-left: 1.5rem;
      font-family: $merriweather;
      font-size: rem(16);
      line-height: 1.8;

      @extend %accent-border;
    }

    &.left {
      margin-right: 1.5rem;
      padding: 0 1.5rem 0 0;
      border-width: 0 rem(7) 0 0;

      @include span-columns(2 of 5);

      & {
        float: left;
        clear: left;
      }
    }
    &.right {
      margin-left: 1.5rem;

      @include span-columns(2 of 5);
      @include omega;

      & {
        float: right;
        clear: right;
      }
    }

    @include mobile {
      &.left, &.right {
        margin: 1rem 0;
        border-width: 0 0 0 rem(7);
        padding: 0 0 0 1.5rem;

        @include span-columns(1 of 1);

        & {
          float: none;
          clear: both;
        }
      }
    }
  }

  blockquote:where(:not(.roundbox)) {
    @extend %pull-quote;
  }

  q {
    &::before {
      content: '\201c';
    }
    &::after {
      content: '\201d';
    }

    &.left, &.right, &.center {
      @extend %pull-quote;
      display: block;

      &::before, &::after {
        content: none;
      }
    }
  }

  %callout {
    &, &.right {
      padding: 1.5rem;
      background-color: $bg-gray; // #f2f2f2
      margin: 1rem {
        right: 0;
      }

      @include span-columns(2 of 5);

      & {
        float: right;
        clear: right;
      }
    }

    &.left {
      float: left;
      clear: left;
      margin: {
        right: 1rem;
        left: 0;
      }
    }

    &.center {
      float: none;
      width: 100%;
      margin: {
        left: 0;
      }
    }

    @include mobile {
      &.left, &.right {
        margin: 1rem 0;

        @include span-columns(1 of 1);

        & {
          float: none;
          clear: both;
        }
      }
    }
  }

  article.dk-content {
    aside:not(.references), .callout {
      @extend %callout;

      :first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }
    }

    aside:not(.references) {
      margin-top: 0;
    }

    .references {
      & > * {
        font-size: rem(12);
        line-height: 1.6;
      }
    }
  }

  // Columns of content
  %content-columns {
    $widths: (
      one-quarter: 3,
      one-third: 4,
      one-half: 6,
      two-thirds: 8,
      three-quarters: 9
    );

    margin: 1rem 0;
    @include row;

    & > ul, & > ol {
      margin: {
        top: 0;
        bottom: 0;
      }

      @include mobile {
        &:not(:first-child) {
          margin-top: 0.5em;
        }

        &:not(:last-child) {
          margin-bottom: 0.5em;
        }
      }
    }

    @include desktop {
      & > ul, & > ol {
        & > li:first-of-type {
          margin-top: 0;
        }

        & > li:last-of-type {
          margin-bottom: 0;
        }
      }

      & > * {
        margin: {
          top: 0;
          bottom: 0;
        }

        @include remove-bookend-margins(child);
      }

      @for $i from 2 through 4 {
        & > :first-child:nth-last-child(#{$i}) {
          &, & ~ * {
            $cols: math.div(12, $i);

            @include span-columns($cols of 12);
          }
        }
      }

      @each $class, $cols in $widths {
        & > .#{$class}.#{$class}.#{$class} {
          @include span-columns($cols of 12);
        }
      }
    }

    @include mobile {
      @each $class, $cols in $widths {
        & > .#{$class}.#{$class}.#{$class} {
          @include span-columns(12 of 12);
        }
      }
    }
  }

  .columns {
    @extend %content-columns;
  }

  // Wrapping columns
  .column-wrap {
    break-inside: avoid-column;
    columns: 2;

    @each $i in (3, 4) {
      &.cols-#{$i} {
        columns: $i;
      }
    }

    @include mobile {
      &:not(.wrap-mobile) {
        columns: 1;
      }
    }

    &.margin {
      margin: 1rem 0;
    }

    &.no-break > *, &:not(.allow-break) > li {
      page-break-inside: avoid;
    }

    & > p {
      @include remove-bookend-margins;
    }
  }

  ul.column-wrap, ol.column-wrap {
    &.loose {
      @include remove-bookend-margins(child);
    }
  }

  .person-columns {
    width: 100%;

    @include row;

    & {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    & > * {
      width: 45%;
      margin-bottom: 1.5rem;

      strong a i {
        display: inline-block;
        margin-left: 0.3rem;

        color: $text-general;
      }

      a.email .i-envelope-o {
        display: none;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }

      @include desktop {
        width: 32%;
      }
    }

    &:after {
      content: "";
      width: 45%;

      @include desktop {
        width: 32%;
      }
    }
  }

  ul, ol {
    &.alternating {
      & > li {
        margin-top: 0.8rem;
      }

      @include desktop {
        @include clearfix;

        & > li {
          width: calc(50% - 1.6em);
          margin-left: 1.3em;

          &:nth-child(2n+1) {
            float: left;
            clear: left;
          }

          &:nth-child(2n) {
            float: right;
            clear: right;
          }

          &:first-child, &:nth-child(2) {
            margin-top: 0;
          }

          &:last-child, &:nth-last-child(2) {
            margin-bottom: 0;
          }
        }

        &.rows > li {
          &:nth-child(2n) {
            margin-left: 1.9em;
            float: left;
            clear: none;
          }

          &:nth-child(2n+1) {
            clear: left;
          }
        }
      }
    }
  }
}

.disclaimer p {
  font-size: .8rem;
}

.caption p {
  font-size: .8rem;
  font-family: $open-sans;
}

.abstract, p.abstract {
  font-weight: bold;
  line-height: 1.5;
}

// Document icons
.docicons {
  fill: $green;
}

%content-svg-spinner {
  content: $svg-spinner;
  animation: rotate 1.2s linear infinite;
}

// Audio player
.audio-pill {
  $c-stopped: #3563a2;
  $c-playing: #597e3e;
  $c-paused: #736214;
  $c-loading: #a3621e;
  $c-error: #bc4323;
  $colors: (
    stopped: $c-stopped,
    playing: $c-playing,
    paused: $c-paused,
    loading: $c-loading,
    error: $c-error
  );
  $height: 40;
  $width: 168;
  $width-wide: 240;
  $font-size: 14;
  $icon-size: $font-size + 2;

  display: inline-block;
  position: relative;
  width: rem($width);
  max-width: 100%;
  height: rem($height);
  border: 0;
  border-radius: rem(math.div($height, 2));
  padding: 0 rem(15) 0 rem(50);
  background-color: $c-stopped;
  color: $white;
  font: {
    family: $open-sans;
    weight: normal;
    size: rem($font-size);
  }
  line-height: math.div($height, $font-size);
  text-align: center;

  -webkit-appearance: none;

  &[data-counter] {
    width: rem($width-wide);
  }

  &:visited {
    color: $white;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: color.adjust($c-stopped, $lightness: -10%);
    cursor: pointer;
  }

  @each $class, $color in $colors {
    &.#{$class} {
      background-color: $color;

      &:hover {
        background-color: color.adjust($color, $lightness: -10%);
      }
    }
  }

  &::before {
    content: $i-play;
    display: block;
    position: absolute;
    top: calc(50% - #{rem(math.div($icon-size, 2))});
    left: rem(math.div(40 - $icon-size, 2) + 3);
    width: rem($icon-size);
    height: rem($icon-size);
    font: {
      family: $dk-icons;
      size: rem($icon-size);
      weight: normal;
    }
    line-height: 1;
    text-align: center;
  }

  &.playing::before {
    content: $i-pause;
  }

  &.loading::before {
    @extend %content-svg-spinner;
  }

  &.error::before {
    content: $i-exclamation-circle;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-left: solid 1px $white;
    top: rem(5);
    bottom: rem(5);
    left: rem(41);
  }

  audio {
    display: none;
  }

  .times {
    font-size: 0.9em;
    margin-left: rem(5);

    &:empty {
      display: none;
    }

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }

  .progress {
    display: none;
    position: absolute;
    bottom: rem(5);
    left: rem(55);
    right: rem(20);
    height: rem(2);
    background-color: rgba($white, 0.3);

    & > div {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
    }
  }

  &.playing, &.paused {
    .progress {
      display: block;
    }
  }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.ratio {
  @extend %constrained-box;

  &.r1-1, &.square {
    @include ratio(1, 1);
  }

  &.r4-3 {
    @include ratio(4, 3);
  }

  &.r12-7, &.blog-image {
    @include ratio(12, 7);
  }

  &.r16-9, &.youtube {
    @include ratio(16, 9);
  }

  &.golden {
    @include ratio(1 + math.sqrt(5), 2);
  }
}

.youtube-container {
  @extend %constrained-box;
  width: rem(400);

  @include ratio(16, 9);

  &.full-width {
    width: 100%;

    & + textarea[readonly] {
      width: 100%;
    }
  }

  &.half {
    width: 50%;

    & + textarea[readonly] {
      width: 50%;
    }

    @include mobile {
      width: 100%;

      & + textarea[readonly] {
        width: 100%;
      }
    }
  }

  & + textarea[readonly] {
    display: block;
    width: rem(400);
    margin: 0 0 1rem;
  }
}

// Dev only (refactor?)
.color-options {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: rem(3);
  border-collapse: separate;

  & > * {
    display: table-cell;
    text-align: center;
    cursor: pointer;
    font-size: rem(14);

    &::before {
      content: '';
      display: block;
      color: $white;
      line-height: math.div(64, 14);
      @extend %accent-background;
    }

    &.fg-dark::before {
      color: $text-general;
    }

    @each $name in get-accent-names() {
      $color: get-accent-color($name);

      &.#{$accent-color-prefix}#{$name}::before {
        content: '#{$color}';
      }
    }

    @each $class, $color in $background-colors {
      &.#{$class} {
        background-color: transparent;

        &::before {
          content: '#{$color}';
          background-color: $color;
        }
      }
    }

    @include mobile {
      font-size: rem(11);
    }
  }
}

%thin-box {
  border-width: 1px;
  border-style: solid;
  margin: 1rem 0;
  padding: 1rem;

  @extend %remove-child-bookend-margins;
  @extend %accent-border;

  &.fg-white {
    &, a, a:after, a:visited {
      color: $white;
    }
  }
}

.thin-box {
  @extend %thin-box;

  &.accented {
    border-top-width: rem(8);
  }
}

.split-60-40 {
  @include desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :first-child {
      max-width: 60%;
    }

    & > :nth-child(2) {
      margin-left: 1.5rem;
    }
  }
}

.program-staff {
  @include row;
  @extend %thin-box;

  ul {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;

    li {
      display: block;
      list-style: none;
      margin: 1rem 0 0;
      padding: 0;

      &:nth-child(1) {
        margin-top: 0;
      }

      a:first-of-type {
        display: block;
      }

      @include desktop {
        width: calc(50% - 0.5rem);
        float: left;

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(2n) {
          margin-left: 1rem;
        }

        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
}

.contact-with-photo {
  display: grid;
  grid-template-columns: #{rem(100)} 1fr;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;

  img {
    display: block;
    max-width: rem(100);
    border-radius: 50%;
  }
}

// Breakpoint-conditional display
main {
  @include mobile {
    .hide-mobile.hide-mobile.hide-mobile.hide-mobile {
      display: none;
    }

    i.hide-mobile {
      font-style: normal;
    }
  }
}

.dk-main-container {
  hr {
    margin: 2rem 0;
    border: solid 1px $divider-stroke;

    @extend %accent-border;
  }
}

@for $num from 2 through 5 {
  .margin-top-#{$num}rem.margin-top-#{$num}rem.margin-top-#{$num}rem {
    margin-top: #{$num}rem;
  }
}

// Person block (for Director's Update Commendations & Commencements)

.person-block {
  @extend %clearfix;
  margin: 1rem 0;

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  & + .person-block,
  & + p {
    padding-top: 1rem;
    border-top: solid 1px $divider-stroke;
  }
}

// Callout Button
.callout-button {
  @extend %remove-child-bookend-margins;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  padding: 2rem;
  border-radius: rem(5);
  background-color: $bg-gray;

  & > * {
    text-align: center;
  }

  @include desktop(true) {
    min-width: rem(400);
    width: fit-content;
  }
}
