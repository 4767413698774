@use 'sass:color';
@use '../tools' as *;

.dk-top-scroll {
  margin-top: 1rem;
  overflow: {
    x: scroll;
    y: hidden;
  }

  &.hidden {
    display: none;
  }

  div {
    height: 0;
  }
}
.dk-table-wrap {
  overflow: auto;
  margin: 1rem 0;

  & > table {
    margin-bottom: 0;
  }
}

.dk-top-scroll:not(.hidden) + .dk-table-wrap {
  margin-top: 0;
}

#{$table-selector} {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  empty-cells: show;
  margin-bottom: 1rem;

  &.tablesaw-stack {
    @include mobile {
      thead {
        td, th {
          display: none;
        }
      }

      tbody {
        td, th {
          clear: left;
          float: left;
          width: 100%;
        }
      }

      td:empty, th:empty {
        display: none;
      }

      td, th {
        .tablesaw-cell-label {
          display: block;
          margin-bottom: rem(4);
        }

        .tablesaw-cell-label-top {
          display: block;
          padding: rem(6) 0;
          margin: rem(6) 0;
        }

        .tablesaw-cell-content {
          display: block;
        }
      }

      tr {
        &:nth-child(odd) {
          background-color: $bg-white;
        }

        &:not(:last-child) {
          border-bottom: 1px solid color.adjust($bg-gray, $lightness: -10%);
        }

        td, th {
          border: 0;

          &:first-child {
            padding-top: rem(20);
          }

          &:last-child {
            padding-bottom: rem(20);
          }
        }
      }
    }
  }

  caption {
    caption-side: bottom;
    text-align: left;
    margin-top: 0.4rem;

    @extend %remove-child-bookend-margins;
  }

  td, th {
    border: 1px solid color.adjust($bg-gray, $lightness: -10%);
    padding: rem(4) rem(12);
    background-color: $bg-white;
    text-align: left;
    font-weight: normal;
    color: $text-general;

    &.tablesaw-cell-hidden,
    .tablesaw-cell-label {
      display: none;
    }
  }

  thead {
    th, td {
      background-color: $blue;
      color: $white;
      font-weight: bold;
    }
  }

  @include with-accent-class {
    thead {
      th, td {
        background-color: var(--accent-bg-color, #{$blue});
      }
    }
  }

  &:not(.no-stripes) tbody tr:nth-child(odd) {
    td, th {
      background-color: $bg-gray;
    }
  }
}

.tablesaw-bar {
  $size: rem(40);

  clear: both;

  &.tablesaw-mode-swipe {
    &.tablesaw-all-cols-visible {
      display: none;
    }

    .tablesaw-advance {
      float: right;

      a.tablesaw-nav-btn {
        width: $size;
        height: $size;
        margin: 0 0 0 rem(4);
        border: 1px solid $button-outline;
        border-radius: rem(4);
        text-decoration: none;

        &:hover {
          background-color: $button-gray;
        }

        &.disabled {
          opacity: .35;
          cursor: default;
          pointer-events: none;
        }

        &::before {
          @extend %icon-base;
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: auto;
          border: 0;
          transform: translateX(-50%) translateY(-50%);
          font-size: rem(24);
          text-indent: 0;
          color: $button-text;
        }

        &.left::before {
          content: $i-angle-left;
        }

        &.right::before {
          content: $i-angle-right;
        }
      }
    }

    .tablesaw-advance-dots {
      list-style: none;
      height: $size;
      margin: 0 1rem;
      line-height: $size;

      li {
        display: inline;
        margin-left: .5rem;

        i {
          width: 0.5rem;
          height: 0.5rem;
          background: $black;
          border-radius: 50%;
          display: inline-block;
        }

        &.tablesaw-advance-dots-hide i {
          background: $light-gray;
        }
      }
    }
  }
}
