@use 'sass:color';
@use '../tools' as *;

@include enable-forms;

// Become a Reviewer form

#rf-root {
  min-height: 4.5rem; // Avoid content shift when form script loads
  overflow: auto; // Prevent margin collapse
}

#reviewer-form {
  .red-required {
    color: $text-red;
  }

  .rf-apply {
    display: block;
    text-align: center;
    width: rem(250);
    border: 1px solid $button-outline;
    border-radius: rem(5);
    margin: 0.5rem 0;
    padding: 1rem 0;
    background-color: $blue;
    font-family: $open-sans;
    font-weight: $semibold;
    color: $white;

    &:hover, &:focus {
      background-color: color.adjust($blue, $lightness: -10%);
      text-decoration: none;
    }

    @include mobile {
      margin: 0 auto;
    }
  }

  .research-area-container {
    columns: rem(250) 2;
    break-inside: avoid-column;

    .research-area {
      margin: 0 0 0.5rem;
      break-inside: avoid-column;
      page-break-inside: avoid;
    }
  }

  .your-selections {
    h4 {
      display: inline-block;
    }
    .clear-selections {
      display: block;
      float: right;
      margin: 1rem 0;
      cursor: pointer;
    }
  }

  .selection {
    display: inline-block;
    margin: 0 rem(5) rem(10);
    border-radius: rem(5);
    padding: rem(3) rem(10) rem(5) rem(8);
    background-color: $green;
    color: $white;

    .delete-selection {
      margin-right: rem(5);
      cursor: pointer;
    }
  }

  .rf-up {
    margin-top: 1.5rem;

    input[type='file'] {
      color: transparent;
    }

    label {
      display: block;
      margin: 1rem 0;
      color: $text-blue;

      &::after {
        content: '*';
        display: inline;
        color: $text-red;
      }
    }

    #reviewer-attachment {
      display: block;
    }

    .selection {
      margin: 0;
    }

    .validation {
      position: relative;

      &::after {
        content: $i-exclamation-circle;
        color: $text-red;
        position: absolute;
        top: rem(-2);
        right: rem(-28);

        font-family: $dk-icons;
        font-size: 1.5rem;
      }

      &.email-val-2::before {
        content: $i-exclamation-circle;
        color: $text-red;
        position: absolute;
        top: rem(-38);
        right: rem(8);
        font-family: $dk-icons;
        font-size: 1.5rem;
      }
    }
  }

  .reviewer-nav {
    font-family: $open-sans;
    font-weight: $semibold;
    text-align: center;

    & > * {
      display: inline-block;
    }

    a {
      color: $text-general;
      text-decoration: none;
    }

    span, a {
      margin: 0 1rem;
      padding: 0.535rem 1rem;
      border-radius: rem(100);
      border: solid 1px $button-outline;
      background-color: $button-gray;

      &.rf-previous {
        &:hover, &:focus {
          background-color: color.adjust($button-gray, $lightness: -10%);
          cursor: pointer;
        }
      }

      &.rf-current {
        background-color: $green;
        color: $text-light;
      }
    }
  }

  p + .field {
    margin-top: -0.6rem;
  }

  .field + .recaptcha {
    margin-top: 2rem;
  }

  .rf-helper {
    margin: 1rem 0;
    font-family: $open-sans;
    font-weight: $semibold;
    color: $black;

    &.gap {
      margin-top: 2.5rem;
    }
  }
}

// Reviewer form OMB information
.rf-omb {
  display: none;

  &.show {
    display: block;
  }
}

.dk-content .g-recaptcha {
  // Prevent reCAPTCHA overflow on small screens (e.g., iPhone 5)
  @include breakpoint($max: 350px) {
    transform: translateX(#{rem(-14)});
  }
}

.gfr-root > .gfr-disclaimer {
  display: none;
}

.dk-form.gfr-calculator {
  border: solid 1px get-accent-color('mauve');
  padding: 0 1rem;

  @include desktop {
    max-width: 60%;
  }

  .field.field {
    max-width: 100%;
  }

  .output {
    text-align: center;

    & > * > * {
      display: inline;
    }
  }

  .result {
    font-size: 1.5em;
    font-weight: bold;

    & + * {
      font-size: 0.9em;
    }

    &:empty + * {
      visibility: hidden;
    }
  }

  .gfr-result {
    margin-bottom: 2rem;
  }

  &.v2 {
    max-width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem 0;

    div[class^='heading'] {
      color: $text-dark;
    }

    div[class^='heading'],
    div[class^='heading'] + .gfr-subheading,
    .buttons,
    .gfr-disclaimer-container {
      grid-column: span 4;
    }

    div[class^='heading'] + .gfr-subheading {
      margin-top: -1.75rem;
      font-weight: normal;
    }

    div.heading3 {
      margin: 0;
      text-align: center;
    }

    .field {
      margin: 0;

      &.text {
        grid-column: span 2;
        margin: -0.75rem 0 0.25rem;

        @include mobile {
          grid-column: span 4;
        }
      }

      &.hide-req {
        label::after {
          display: none;
        }
      }


      &.options {
        grid-column: span 4;

        .group {
          display: flex;
          flex-wrap: wrap;

          & > div {
            margin-right: 3rem;
          }

          @include mobile {
            flex-direction: column;
          }
        }
      }

      @include mobile {
        grid-column: span 4;
      }
    }

    .gfr-instruction {
      grid-column: span 4;
      margin: -0.125rem 0 -0.25rem;
      font-weight: normal;

      &::after {
        content: '*';
        display: inline;
        color: $text-red;
      }
    }

    .buttons button.centered {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .alert {
      margin-top: 0.25rem;
      padding-left: 1rem;
      font-family: $merriweather;
      font-weight: normal;
    }

    .gfr-result {
      grid-column: span 2;
      margin-bottom: 0;
      padding: 1rem 1rem 2.5rem;
      border: rem(6) solid $bg-ash;
      background-color: $bg-ash;

      &.centered {
        grid-column: 2 / 4;
      }

      &.highlighted {
        border-color: $text-teal;
      }

      div[class^='heading'] {
        padding: 0 1.5rem;
        text-align: center;
      }

      .gfr-result-value {
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;
        column-gap: 0.5rem;
        span:first-child {
          font-size: 3rem;
          font-weight: bold;
          color: $text-teal;
        }
        span:last-child {
          font-size: 1.5rem;
          color: $text-dark;
        }
      }

      @include mobile {
        &,
        &.centered {
          grid-column: span 4;
        }
      }
    }

    .gfr-stats {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 3rem;

        @include mobile {
          padding: 0;
        }

      &:not(.centered) {
        grid-column: span 4;
      }

      span {
        display: inline-block;
        margin: 0.33rem 0.5rem;
        padding: 0.25rem 1rem;
        border-radius: rem(5);
        color: $white;
        background-color: $link-hover;
      }
    }
  }
}
