@use 'sass:list';
@use 'functions' as *;
@use 'mixins' as *;
@use 'icons' as *;
@use 'variables' as var;

@mixin enable-utility-classes {
  .clearfix {
    @extend %clearfix;
  }

  .clear {
    @extend %clear-both;
  }

  .unselectable {
    @extend %unselectable;
  }

  .no-bookends {
    @extend %remove-bookend-margins;
  }

  .no-child-bookends {
    @extend %remove-child-bookend-margins;
  }

  @each $prop in (margin, padding) {
    .no-#{$prop}.no-#{$prop}.no-#{$prop}.no-#{$prop} {
      #{$prop}: 0;
    }

    @each $pos in (top, bottom, left, right) {
      .no-#{$prop}-#{$pos}.no-#{$prop}-#{$pos}.no-#{$prop}-#{$pos}.no-#{$prop}-#{$pos} {
        #{$prop}-#{$pos}: 0;
      }
    }
  }

  .show {
    @extend %show-element;
  }

  .hide {
    @extend %hide-element;
  }

  .text-centered {
    @extend %text-centered;
  }

  .sr-only {
    @extend %sr-only;
  }

  .sr-only-focusable {
    @extend %sr-only-focusable;
  }

  svg.svg-defn {
    @extend %sr-only;
  }

  .strong {
    @extend %text-bold;
  }

  .unbold {
    @extend %text-unbold;
  }

  .text-dark {
    @extend %text-dark;
  }

  .word-wrap {
    @extend %word-wrap;
  }

  .text-small {
    @extend %text-small;
  }

  .sans-serif {
    @extend %sans-serif;
  }

  .semibold {
    @extend %text-semibold;
  }

  .intro-text {
    @extend %dk-intro-text;
  }
}

@mixin dk-grid($withMobile: 1) {
  max-width: rem(var.$container-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 rem(list.nth(var.$container-pad, 1));

  @include clearfix;

  @if $withMobile == 1 {
    @include mobile {
      padding: 0 rem(list.nth(var.$container-pad, 2));
    }
  }
}

%dk-grid {
  @include dk-grid;
}

%clearfix {
  @include clearfix;
}

%unselectable {
  @include unselectable;
}

%remove-bookend-margins {
  @include remove-bookend-margins;
}

%remove-child-bookend-margins {
  & > * {
    @extend %remove-bookend-margins;
  }
}

%clear-both {
  clear: both;
}

%icon-hover-fix {
  @include icon-hover-fix;
}

%show-element {
  display: initial;
  visibility: visible;
}

%hide-element {
  display: none;
  visibility: hidden;
}

%sr-only {
  @include sr-only;
}

%sr-only-focusable {
  @include sr-only-focusable;
}

%text-centered {
  text-align: center;
}

%text-bold {
  font-weight: 700;
}

%text-semibold {
  font-weight: var.$semibold;
}

%text-unbold {
  font-weight: 400;
}

%text-dark {
  color: var.$text-dark;
}

%sans-serif {
  font-family: var.$open-sans;
}

%text-small {
  &, & p {
    font-size: rem(12.5);
  }
}

%word-wrap {
  overflow-wrap: break-word;
}


// Possibly site-specific

%h2-standard {
  color: var.$text-dark;
  font: normal 1.5em/2.5rem var.$merriweather;
}

%news-dateline {
  font-family: var.$open-sans;

  .news-type {
    margin-right: rem(10);
  }
}

%constrained-box-centered-child {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  transform: translateX(-50%) translateY(-50%);
}

%constrained-box-centered {
  & > * {
    @extend %constrained-box-centered-child;
  }
}

%constrained-box {
  display: block;
  position: relative;

  &.fit-horizontal.fit-horizontal > * {
    max-width: 100%;
    max-height: none;
  }

  &.fit-vertical.fit-vertical > * {
    max-width: none;
    max-height: 100%;
  }

  &.fit.fit > * {
    max-width: 100%;
    max-height: 100%;
  }

  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ratio {
    width: 100%;
  }

  img {
    width: auto;
    height: auto;
  }

  &.centered {
    @extend %constrained-box-centered;
  }

  &.bg-black {
    background-color: var.$black;
  }
}

%video-corner {
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  width: 20%;
  text-align: left;

  @include ratio(1, 1);

  & {
    // The ratio mixin adds position: relative, so this needs to specifically come after it
    position: absolute;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .crn-fg {
    fill: var.$white;
  }
}

%news-horizontal-date-links {
  margin: 1.5rem 0;
  font-family: var.$open-sans;
  font-size: 0;

  & > * {
    display: inline-block;
    margin: rem(4) rem(10) rem(4) 0;
    border-right: 1px solid var.$text-general;
    padding-right: rem(10);
    font-size: 1.2rem;

    &:last-child {
      margin-right: 0;
      border: 0;
      padding: 0;
    }
  }

  &:not(.default-links) a {
    text-decoration: none;

    &, &:hover, &:active, &:visited {
      color: var.$text-general;
    }

    &:hover, &:active {
      text-decoration: underline;
    }
  }

  span {
    font-weight: var.$semibold;
    color: var.$text-dark;
  }
}

%dk-tag {
  display: inline-block;
  border-radius: rem(3);
  min-width: rem(70);
  color: var.$white;
  font-size: rem(12);
  font-family: var.$open-sans;
  text-align: center;
}
