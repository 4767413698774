@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use '../tools' as *;

// Contact Us
#contact {
  background-image: linear-gradient(#{color.mix(#fff, $bg-ash, 80%)}, $white 0%, $bg-ash);
  padding-bottom: 1.5rem;
  font-family: $open-sans;
  color: $text-general;

  h2 {
    @extend %h2-standard;
    border-bottom: 1px $divider-stroke solid;
    padding-top: 1em;
    margin-top: 0;
    color: $text-general;
  }

  h3 {
    margin-top: 0;
  }

  address {
    color: $text-general;
    font-style: normal;

    a {
      color: $text-general;
      text-decoration: none;

      .lnk {
        color: $link-standard;
      }

      &:hover, &:active {
        text-decoration: none;

        .lnk {
          color: $link-hover;
          text-decoration: underline;
        }
      }
    }

    [class*="i-"] {
      font-size: 1.2em;
      display: inline-block;
      width: rem(25);
      text-align: center;
      vertical-align: middle;
    }

    .i-mobile {
      font-size: 1.4em;
    }
  }

  .contact-flex {
    display: flex;
    flex-flow: wrap row;

    &:not(.v2) address i {
      margin-right: rem(5);
    }

    &.v2 {
      address {
        & > * {
          display: grid;
          grid-template-columns: rem(30) 1fr;

          & > :last-child {
            align-self: center;
          }
        }
      }
    }

    & > * {
      flex: 1 0 auto;
      margin-bottom: 1rem;
      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0;
      }

      @include mobile {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
    }

    address {
      max-width: rem(340);

      & > .print-social {
        display: none;
      }
    }

    .live-chat {
      max-width: rem(270);
    }

    .follow-us {
      justify-self: flex-end;
      max-width: 300px;
    }
  }

  .footer-live-chat {
    display: flex;
    align-items: flex-start;
    gap: rem(10);

    & > :last-child {
      margin-left: 0.5rem;
      line-height: 1.1;
    }
  }

  .follow-us {
    h3 a {
      text-decoration: none;

      &, &:visited, &:hover {
        color: inherit;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    $icon-size: rem(30);
    $circle-size: $icon-size + 2 * math.div($icon-size, 3);
    $fc-icons: (
      twitter: $twitter-blue $i-twitter-c,
      facebook: $facebook-blue $i-facebook-c,
      //rss: $rss-orange $i-rss,
      youtube: $youtube-red $i-youtube-c,
      instagram: $instagram-purple $i-instagram,
      linkedin: $linkedin-blue $i-linkedin-c,
      x-social: $x-social-black $i-x-social-c
    );

    %footer-follow-button {
      font-size: 0;
      text-indent: 5000rem;
      text-decoration: none;

      &::before {
        @extend %icon-base;
        text-indent: 0;
        font-size: $circle-size;
      }

      & + a {
        margin-left: 0.5rem;
      }
    }

    @each $cls, $data in $fc-icons {
      $color: list.nth($data, 1);
      $icon: list.nth($data, 2);

      a.#{$cls} {
        color: $color;
        @extend %footer-follow-button;

        &:hover {
          @if $cls == 'x-social' {
            color: color.adjust($color, $lightness: 12%);
          }
          @else {
            color: color.adjust($color, $lightness: -12%);
          }
        }

        &::before {
          content: $icon;
        }
      }
    }
  }

  @include mobile {
    padding: 0 1rem 1rem;
  }
}

/** footer **/
#main-footer {
  $border-stroke: #787a77;

  background-image: linear-gradient($bg-charcoal, #3e403d);
  color: $white;

  .tagline {
    text-align: center;
    font-family: $open-sans;
  }

  .sub-footer {
    background: $bg-onyx;
    color: $white;
    text-align: center;
    padding: 1.5rem 0;
    font-family: $open-sans;

    a {
      color: $white;
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }

  nav {
    margin-top: 2rem;
  }

  nav > section {
    padding-bottom: 2rem;
    font-family: $open-sans;

    @include span-columns(3);

    a {
      text-decoration: none;
      color: $white;

      &:hover, &:active {
        text-decoration: underline;
      }
    }

    h3 {
      border-bottom: $border-stroke 2px solid;
      font-size: 1.3rem;
      font-weight: normal;
      padding-bottom: 1.2rem;
      margin-bottom: 0.8rem;
      font-family: $merriweather;

      [class*="i-"] {
        display: none;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      line-height: 1.2rem;

      li {
        padding: 0.3rem;
      }
    }
  }

  @include mobile {
    nav {
      margin: 1rem 0 0;
      padding: 0;

      .tagline {
        margin-bottom: 1rem;
      }

      & > section {
        padding: 0;

        @include span-columns(12);
        @include omega;

        &:first-of-type {
          border-top: $border-stroke 2px solid;
        }

        h3 {
          display: block;
          position: relative;
          margin: 0;
          padding: 0 {
            right: 4rem;
          }
          font-family: $open-sans;
          cursor: pointer;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 4rem;
            background-color: color.adjust($bg-charcoal, $lightness: 5%);
          }

          & > a {
            display: block;
            padding: 1rem;
          }

          & > i[class*="i-"] {
            display: block;
            position: absolute;
            top: calc(50% - #{math.div(rem(18), 2)});
            right: 0;
            width: 4rem;
            text-align: center;
            font-size: rem(18);
            line-height: 1;
          }
        }

        ul {
          display: none;
          background-color: color.adjust($bg-charcoal, $lightness: 5%);

          &.show {
            display: block;
          }

          li {
            border-bottom: solid 1px $border-stroke;
            padding: 0;

            &:last-child {
              border-bottom-width: 2px;
            }

            a {
              display: block;
              padding: 0.7rem 1rem 0.7rem 2rem;
            }
          }
        }
      }
    }

    .sub-footer {
      margin-top: 0;

      ul {
        text-align: left;
        left: 1rem;
        border-top: rgba($border-stroke, 0.4) 0.5rem double;
        margin-bottom: 2rem;

        li {
          padding: 0.5rem 1rem;
          border-bottom: rgba($border-stroke, 0.4) 0.15rem solid;

          @include row;
          @include omega;
        }
      }
    }
  }
}
