@use 'sass:color';
@use 'sass:math';
@use '../tools' as *;

.sc-slider {
  position: relative;

  &.sc-healthy-moments {
    .sc-card {
      .sc-card-header a {
        @include line-clamp(2, 12, 1.4);
      }

      .sc-card-copy .description {
        @include line-clamp(5, 12.5, 1.4);
      }
    }
  }

  &.sc-news, &.sc-events, &.sc-reports {
    .sc-card.view-more .sc-card-image {
      background-image: linear-gradient(135deg, $site-nav-current, $site-nav);
    }

    .sc-card-image {
      background-image: linear-gradient(135deg, #{color.adjust(#527b8b, $lightness: 40%)}, #{color.adjust(#527b8b, $lightness: 10%)});

      & > div {
        background-image: url(../images/bg-polygon.png);
      }
    }
  }

  &.sc-reports .sc-cards .sc-card .sc-card-image > div {
    @include ratio(93, 120);
  }

  &.sc-reports .sc-card-header.line-clamp {
    @include line-clamp-grow(4, 12, 1.4);
  }
}

section.sc-slider {
  @include desktop
  {
    .grid:first-child {
      position: relative;

      h2 + a.cta {
        position: absolute;
        right: rem(30);
        top: rem(12);
        margin: 0;
      }
    }

    .grid:last-child a.cta {
      margin: 0;
    }
  }

  @include mobile {
    h2 + a.cta {
      margin: 0 0 1rem;
    }

    .grid:last-child a.cta {
      margin: 0.5rem 0 0;
    }
  }
}

.dk-two-column .sc-slider {
  margin: 2rem 0;

  @include desktop {
    .grid {
      padding: 0;
    }
  }

  .sc-inner-wrap {
    position: relative;
  }

  .sc-inner-wrap + .grid {
    margin: 1rem 0 2rem;
  }
}

// Make the slider full-width on mobile on two-column pages
@include mobile
{
  .dk-two-column .dk-main-container .dk-content .sc-slider .sc-inner-wrap {
    margin: {
      left: rem(-24); // Counteract padding on .dk-main-container and .dk-content
      right: rem(-24);
    }
  }
}

.sc-cards {
  max-width: 100%;
  overflow: hidden;

  &.slick-slider {
    margin-bottom: 0;
  }

  .slick-list {
    padding-right: rem(2);
  }

  .slick-dots {
    position: static;

    @at-root .dk-two-column & {
      @include desktop {
        height: rem(30);
        margin-top: 0.5rem;
      }
    }

    li {
      margin: 0;
      width: rem(15);

      button:before {
        content: "\25cf";
      }
    }

    button:before {
      font-size: 0.9rem;
      opacity: .6;
      right: 0;
      left: 0;
    }

    .slick-active {
      button:before {
        content: "\25cb";
      }
    }
  }

  .sc-card {
    $base-border-radius: rem(3) !default;
    $base-spacing: 1.5rem !default;
    $card-margin: .7rem;

    @at-root .dk-two-column {
      $card-margin: .3rem;
    }

    & {
      transition: all 0.2s ease-in-out;
      background-color: $white;
      border-radius: $base-border-radius;
      box-shadow: $tile-shadow;
      margin: rem(2) $card-margin rem(5) $card-margin;
      position: relative;
      display: table-cell;
      font-family: $open-sans;
    }

    @include mobile-tablet {
      max-width: rem(400);
    }

    &.view-more {
      .sc-card-header {
        text-align: center;
        font-size: 1.3em;
      }
    }

    a {
      text-decoration: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:visited {
        .sc-card-header {
          color: $link-visited;
        }
      }

      &:hover, &:active, &:focus {
        cursor: pointer;

        .sc-card-header {
          text-decoration: underline;
          color: $link-hover;
        }
      }
    }

    .sc-card-image {
      overflow: hidden;
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        opacity: 1;
        border: 0;
        object-fit: contain;
      }

      & > div {
        border-radius: rem(3) rem(3) 0 0;
        @include ratio(4, 3);

        img {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          width: auto;
          height: auto;
          max-width: 101%;
          max-height: 101%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    .sc-card-header {
      transition: all 0.2s ease-in-out;
      margin: math.div($base-spacing, 1.5) math.div($base-spacing, 2) 0 math.div($base-spacing, 2);
      font: {
        family: $open-sans;
        size: 0.9rem;
        weight: bold;
      }
      line-height: 1.4;
    }

    .sc-card-copy {
      font-size: 0.8rem;
      margin: math.div($base-spacing, 3) math.div($base-spacing, 2);

      p {
        margin: math.div($base-spacing, 3) 0 math.div($base-spacing, 2);
        font: {
          family: $open-sans;
          size: rem(12.5);
        }
        line-height: 1.4;
        color: $text-dark;
      }

      ul {
        font-size: rem(12.5);
      }
    }
  }

  @include mobile {
    -webkit-text-size-adjust: 100%;
  }
}

.sc-slider-paginator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $desktop-max;
  margin: 0 auto;

  @include breakpoint($max: $desktop-max) {
    width: 100%;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    height: rem(70);
    width: rem(70);
    color: $white;
    text-align: center;
    background-color: color.adjust( $medium-gray, $lightness: 20%);
    border: 0;
    border-radius: rem(5);
    opacity: 0.8;
    cursor: pointer;
    -webkit-appearance: none;

    @include mobile {
      display: none !important; // !important needed because of inline styling from the JS plugin
    }

    &:focus {
      outline: none;
    }

    &::before {
      @extend %icon-base;
      top: -1px;
      font-size: rem(40);
      line-height: math.div(70, 40);
    }

    &.sc-previous {
      left: 0;

      &::before {
        content: $i-chevron-left;
      }
    }

    &.sc-next {
      right: 0;

      &::before {
        content: $i-chevron-right;
      }
    }

    .icon {
      fill: currentColor;
    }
  }

  @at-root .dk-two-column & {
    position: absolute;
    top: auto;
    width: 100%;
    height: rem(30);
    margin: 0;

    button {
      width: rem(45);
      height: rem(30);

      &::before {
        font-size: rem(25);
        line-height: math.div(30, 25);
      }

      &.sc-previous {
        left: 25%;
      }

      &.sc-next {
        right: 25%;
      }
    }
  }
}
