@use 'sass:color';
@use '../tools' as *;

%dk-site-search {
  display: flex;
  position: relative;
  font-size: 0;
  max-width: 100%;

  @include dropdown-generic;

  & > * {
    font-family: $open-sans;
    color: $button-text;
  }

  & > button, & > input {
    border: 1px solid $button-outline;
    height: 3.2rem;
    font-size: rem(12.5);
    vertical-align: top;
  }

  button {
    background: $button-gray;
    cursor: pointer;
    padding: 0;

    &:hover {
      background: color.mix(#000, $button-gray, 5%);
    }

    &:active {
      background: color.mix(#000, $button-gray, 10%);
    }
  }

  input[type=text] {
    flex: 1 1 auto;
    max-width: calc(100% - #{rem(105)});
    padding: 0 1em;
    border-radius: 0;
    appearance: none;

    &::placeholder {
      color: color.mix(#fff, $button-text, 25%);
    }
  }

  .ss-go {
    width: rem(60);
    min-width: rem(60);
    border-left: 0;
    border-radius: 0 rem(3) rem(3) 0;
  }

  .ss-options {
    width: rem(45);
    min-width: rem(45);
    border-right: 0;
    border-radius: rem(3) 0 0 rem(3);
  }
}
