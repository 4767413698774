@use '../tools' as *;

@media print {
  body {
    background-color: $white;
  }

  %-display-none {
    display: none;
  }

  %-display-none-important {
    display: none !important;
  }

  .usa-banner {
    @extend %-display-none;
  }

  .stripe-brand {
    .dk-site-logos {
      max-width: 3.25in;
    }
  }

  #main > .grid {
    padding: 1px;
  }

  .dk-main-container {
    margin-top: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;

    .dk-leftnav {
      @extend %-display-none-important;
    }

    .dk-content {
      padding: 0;
    }
  }

  #main-footer {
    .live-chat {
      @extend %-display-none;
    }

    #contact {
      background: $white;

      &, & > .grid {
        padding: 0;
      }

      h2 {
        display: none;
      }

      address {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin: 0;
        border-top: 2px solid $divider-stroke;

        & > link, & > meta, strong, .contact-time, .contact-tty {
          display: none;
        }

        & > *:not(meta, link) {
          margin-top: 3px;
          padding: 0 20px;

          span {
            color: $text-general;
            font-weight: $semibold;
          }

          &:is(.contact-email, .contact-mobile) {
            position: relative;

            &::after {
              position: absolute;
              content: "";
              height: 1.8rem;
              border-right: 2px solid $button-gray;
            }

            &.contact-email::after {
              right: -2px;
            }

            &.contact-mobile::after {
              top: 1px;
              right: 0;
            }
          }
        }

        .print-social {
          display: flex;
          align-items: center;

          svg {
            width: 7.5rem;
            height: 1.25rem;
            margin-right: 0.1in;
            color: $text-general;
          }

          span {
            margin-bottom: 1px;
          }
        }
      }
    }

    .tagline {
      display: none;
    }
  }

  // Live chat floating badge
  .chat-floating {
    @extend %-display-none-important;
  }

  // Interrupter survey
  .interrupter-survey {
    display: none;
  }
}
