@use 'sass:color';
@use '../tools' as *;

.roundbox-grid {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  // Shorter aspect ratio Grid
  &.rg-short {
    .roundbox-grid-row {
      .roundbox-grid-imgs {
        aspect-ratio: 8 / 5;
      }

      @include fixed-layout {
        .roundbox-grid-content {
          aspect-ratio: 8 / 5;
        }
      }

    }
  }

  // Taller aspect ratio Grid
  &.rg-tall {
    .roundbox-grid-row {
      .roundbox-grid-imgs {
        aspect-ratio: 1 / 1;
      }

      @include fixed-layout {
        .roundbox-grid-content {
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  .roundbox-grid-row {
    display: flex;

    .roundbox-grid-imgs {
      position: relative;
      aspect-ratio: 4 / 3;
      overflow: hidden;

      div {
        position: absolute;
        background-size: cover;
        background-position: center;
      }
    }

    &.rg-animated {
      .roundbox-grid-imgs div {
        transform: translateX(0);
        opacity: 1;
        transition-timing-function: linear;
        transition-duration: 1s;
        transition-property: all;

        @media (prefers-reduced-motion) {
          transition-duration: 0s;
        }
      }

      &:where(.fade-lr, .fade-rl, .fade-zoom, .pan-lr, .pan-rl) {
        .roundbox-grid-imgs div {
          scale: 1.1;
        }
      }

      &:is(.fade-lr, .fade-rl, .fade-zoom) {
        .roundbox-grid-imgs .inactive {
          opacity: 0;
        }
      }

      &.fade-lr {
        .inactive {
          transform: translateX(4%);
        }
      }

      &.fade-rl {
        .inactive {
          transform: translateX(-4%);
        }
      }

      &.fade-zoom {
        .inactive {
          scale: 1;
        }
      }

      &.pan-lr {
        .roundbox-grid-imgs div {
          transform: translateX(-4%);

          &.inactive {
            transform: translateX(4%);
          }
        };
      }

      &.pan-rl {
        .roundbox-grid-imgs div {
          transform: translateX(4%);

          &.inactive {
            transform: translateX(-4%);
          }
        }
      }

      &.zoom {
        .inactive {
          scale: 1.1;
        }
      }
    }

    .roundbox-grid-content {
      .content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        :first-child {
          margin-top: auto;
        }

        :last-child {
          margin-bottom: auto;
        }

        @include headings {
          font-size: 1.25rem;

          a {
            line-height: inherit;
          }
        }

        & > *:not(:last-child) {
          margin-bottom: 0;

          :is(p) {
            line-height: 1.6;
          }
        }
      }
    }

    @include fixed-layout(true) {
      .roundbox-grid-content {
        aspect-ratio: 4 / 3;
        padding: 1.25rem;
      }

      & > div {
        width: 50%;
      }

      .roundbox-grid-imgs > div {
        width: 100%;
        height: 100%;
      }

      &:nth-child(odd) {
        .roundbox-grid-imgs {
          border-radius: rem(10) 0 0 rem(10);
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        @media speech {
          flex-direction: row;
        }

        .roundbox-grid-imgs {
          border-radius: 0 rem(10) rem(10) 0;
        }
      }
    }

    @include variable-layout {
      flex-direction: column;
      max-width: 400px;

      .roundbox-grid-content {
        padding: 1.5rem 0;
      }

      .roundbox-grid-imgs {
        border-radius: rem(10) rem(10) 0 0;

        & > div {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include print {
      div:first-child {
        print-color-adjust: exact;
      }

      .inactive {
        opacity: 1;
      }
    }
  }
}
