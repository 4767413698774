@use 'sass:color';
@use '../tools' as *;

// Pop-up content should be hidden (but visible to screen readers) when not displayed in a lightbox
.dk-main-container .popup {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;

  // Allow the option to hide from screen readers
  &.no-sr {
    display: none;
  }
}

.mfp-bg {
  opacity: 0.3;
}

.mfp-content {
  position: relative;
  padding: 3.5rem 1rem 1rem;
  background-color: $white;
  font-family: $merriweather;

  & > * {
    $popup-titles: (
      no-title: '',
      dictionary: 'Dictionary',
      sample-message: 'Sample Message'
    );

    display: block;

    &::before {
      content: 'Details';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2.5rem;
      line-height: 2.2;
      color: $white;
      padding-left: 1rem;
      font-family: $montserrat;
      font-size: 1.1rem;
      background-image: linear-gradient(0deg, $site-nav, #{color.mix(#000, $site-nav, 6.6%)} 72%);
    }

    &[data-title]::before {
      content: attr(data-title);
    }

    @each $class, $title in $popup-titles {
      &.#{$class}:not([data-title])::before {
        content: $title;
      }
    }

    @extend %remove-child-bookend-margins;
  }

  &.mfp-content {
    @include desktop {
      width: 40rem;
      max-width: 90vw;
    }
  }

  .mfp-close {
    color: $white;
    opacity: 1;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 1rem;
  }

  p {
    line-height: 1.8rem;
  }

  a {
    &, &:visited {
      color: $link-standard;
      text-decoration: none;
    }

    &:hover, &:active {
      color: $link-hover;
      text-decoration: underline;
    }

    &.print::before {
      @include icon-base;
      content: $i-print;
      font-size: 1.4em;
      vertical-align: middle;
      color: $text-general;
      margin-right: 0.5rem;
      @extend %icon-hover-fix;
    }

    &.button {
      text-decoration: none;
      color: $white;
    }
  }

  @include enable-images-figures;

  a, i {
    &.eld {
      color: $text-green;
      font-size: 0;
      text-indent: 5000rem;

      &::before {
        font-size: 1rem;
        text-indent: 0;
      }
    }
  }
}
